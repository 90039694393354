/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab } from '../ui-kit/styled-templates'
import UserTab from './user-tab'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let BalanceTab = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let [balance, setBalance] = useState(0)
    let [balanceInput, setBalanceInput] = useState(100)

    useCustomDispatch(`LOAD_CURRENT_BALANCE`, () => {
        customHandler(`LOAD_TRANSACTIONS`)
        let mounted = true
        setBalance(`loading`)
        axios.get(`${API_ENDPOINT}/user/${user_id}/balance`).then((d) => { if (mounted) { setBalance(d.data.balance) } })
        return () => mounted = false
    })

    useEffect(() => {
        customHandler(`LOAD_CURRENT_BALANCE`)
    }, [user_id])

    return (
        <Tab>
            <Frame extra={`width: 100%; flex-direction: row; justify-content: space-between; > * { font-size: 13px; }; margin-bottom: 15px;`} >
                <Frame>Current balance</Frame>
                <Frame extra={props => `font-size: 13px;`} >
                    {balance} RUB
                </Frame>
            </Frame>
            <MinusButton onClick={() => { setBalanceInput(-balanceInput) }} />
            <Input type={`number`} value={+balanceInput} onChange={(e) => { setBalanceInput(+e.target.value) }} extra={`width: calc(100% - 110px - 34px * 2);`} pattern={`[0-9]*`} />
            <Button
                extendedIndicators={true}
                extra={`width: 100px;`}
                onClick={async () => {
                    try { await axios.post(`${API_ENDPOINT}/admin_fillup`, { userId: user_id, sum: +balanceInput }) } catch (error) { console.log(error); }
                    // try { setBalance((await axios.get(`${API_ENDPOINT}/user/${user_id}/balance`)).data.balance) } catch (error) { console.log(error); }
                    customHandler(`LOAD_CURRENT_BALANCE`)
                }}
            >
                Add
            </Button>
        </Tab>
    )
}

const MinusButton = styled(Frame)`

    width: 30px;
    height: 30px;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.red};
    box-sizing: border-box;
    color: ${props => props.theme.red} !important;
    font-weight: bold;
    cursor: pointer;

    &:after {
        content: '-';
    }
`;

export default BalanceTab;
/*eslint-enable*/