/*eslint-disable*/
import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'


import { Frame, Text, H1 } from '../ui-kit/styled-templates'
import Button from '../ui-kit/Button'
import PopUpWrapper from '../pop-ups/pop-up-wrapper'

import CommonHelper from '../../utils/common-helper'

import useCurrentUser from '../../hooks/useCurrentUser'
import useComponentSize from '../../hooks/useComponentSize'
import useCustomDispatch from '../../hooks/useCustomDispatch'

let LogOutPopUp = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)

    useCustomDispatch(`OPEN_LOGOUT_POP_UP`, (e) => { })
    useCustomDispatch(`CLOSE_LOGOUT_POP_UP`, (e) => { })

    let onCloseLogOut = () => { window.dispatchEvent(new CustomEvent(`CLOSE_LOGOUT_POP_UP`, {})) }

    let { logOut } = useCurrentUser()

    return (
        <PopUpWrapper name={`LOGOUT`} >
            <H1 extra={`align-items: center; text-align: center; @media only screen and (max-width: 600px) { width: 90vw; };`} >Are you sure you want to exit?</H1>
            <Frame row extra={`margin-top: 30px;`} >
                <Button extra={`width: 196px; @media only screen and (max-width: 600px) { width: 43.75vw; };`} background={props => props.theme.blue} shaped onClick={onCloseLogOut} >
                    Cancel
                </Button>
                <Button extra={`width: 196px; margin-left: 20px; @media only screen and (max-width: 600px) { width: 43.75vw; margin-left: 2.5vw; };`} background={props => props.theme.red} onClick={() => {
                    logOut()
                    onCloseLogOut()
                }} >
                    Yes, logout
                </Button>
            </Frame>
        </PopUpWrapper>
    );
}

const Avatar = styled(Frame)`
    width: 142px;
    height: 142px;
    border-radius: 142px;
    background: ${props => props.theme.background.secondary};
    position: absolute;
    top: calc(50% - 190px - 71px);
    box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
`;

let Cross = styled.img.attrs((props) => {
    let img
    try { img = require(`../../assets/images/cross_w.svg`).default } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: calc(50% - 300px);
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    width: 600px;
    height: 250px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: ${props => props.visible ? `calc(50% - 190px)` : `150%`};
    z-index: 3;
    // justify-content: space-between;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;


const Wrapper = styled(Frame)`
    position: relative;
    padding-top: 94px;
    overflow: hidden;
`;

export default LogOutPopUp;

/*eslint-enable*/