/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown } from '../ui-kit/styled-templates'

import { API_ENDPOINT } from '../../constants/config'
import CommonHelper from '../../utils/common-helper'

import useConnectionParams from '../../hooks/useConnectionParams'

import useCurrentUser from '../../hooks/useCurrentUser'
import useKeyHandler from '../../hooks/useKeyHandler'
import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch';

let ConnectionOverview = (props) => {

    let { id } = props

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams(id)

    // let user_id = path[1]
    // let connection_id = path[3]

    let { phone, user } = useMappedState(useCallback((state) => {
        let phone = state.phones.phonesMap.get(id)
        return ({
            // users: state.users.usersMap.toArray().filter(i => i.userRole === `franchisee`).map(i => ({ ...i, phones: state.phones.phonesMap.toArray().filter(j => j.userId === i.id || j.sharedUsersEmails.indexOf(i.email) > -1).map(i => i.id) })),
            // loading: state.users.loading || state.phones.loading,
            // phones: state.phones.phonesMap.toArray(),
            // plans: state.plans.plansMap.toArray(),
            phone: phone,
            user: state.users.usersMap.get(phone.userId),
        })
    }, [id]))

    let dispatch = useDispatch()

    useEffect(() => {
        // dispatch(phonesActions.loadVisibleProxies([phone]))
        // dispatch(phonesActions.loadVisibleStatuses([user]))
    }, [id])

    let item = phone
    let is_online = item.status.online === true
    let any_errors = columns.map(i => i.red === undefined ? false : +i.red(item)).reduce((a, b) => a + b, 0) > 0
    return (
        <Connection any_errors={false} onClick={() => { }} >
            {
                columns.map((column, index) => {
                    let red = column.red !== undefined ? column.red(item) : false
                    let green = column.green !== undefined ? column.green(item) : false
                    let yellow = column.yellow !== undefined ? column.yellow(item) : false
                    let blue = column.blue !== undefined ? column.blue(item) : false
                    return (
                        <Prop
                            extra={props => `
                                width: ${column.width}px;
                                color: ${red ? props.theme.red : green ? props.theme.green : yellow ? props.theme.yellow : blue ? props.theme.blue : props.theme.background.secondary};
                                font-weight: ${red || green || yellow || blue ? `bold` : `bolder`};
                                ${column.extra};
                            `}
                            onClick={() => { if (column.onClick !== undefined) { column.onClick(item) } }}
                            key={index}
                        >
                            {column.value(item)}
                        </Prop>
                    )
                })
            }
            <Dropdown menu={[
                {
                    label: `Details`,
                    onClick: () => { CommonHelper.linkTo(`/user/${item.userId}/connection/${item.id}`) },
                },
                {
                    label: `Change IP`,
                    disabled: item.isRoot === false && (item.macrosUrl === `` || item.macrosUrl === undefined) || any_errors,
                    onClick: () => {
                        customHandler(`CHANGE_IP`, item)
                    },
                },
                {
                    label: `Delete`,
                    // color: props => props.theme.red,
                    onClick: () => { },
                    confirm: true,
                    extra: props => `color: ${props.theme.red};`,
                },
            ]} >
                <MoreImage />
            </Dropdown>
        </Connection>
    )
}

const Prop = styled(Frame)`
    /* border: 1px solid red; */
    /* border-right: 1px solid ${props => props.theme.background.secondary}; */
    font-size: 12px;
    align-items: flex-start;
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    /* cursor: default; */
`;

const MoreImage = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/more_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    position: absolute;
    right: 0px;
    &:hover {
        transform: scale(1.2);
    }
`;

const LogoutImage = styled(Frame)`
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }
    &:after {
        content: '';
        width: 15px;
        height: 15px;
        background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/logout_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    }

`;

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
`;

const Left = styled(Frame)`
    width: 300px;
    height: 100%;
`;

const LeftHeader = styled(Frame)`
    flex-direction: row;
`;

const UsersList = styled(Frame)`
    width: 300px;
    height: calc(100% - 50px - 10px);
    margin-top: 10px;
    display: ${props => props.visible ? `block` : `flex`};
    overflow-y: scroll;
`;

const TextPlaceholder = styled(Frame)`
    font-size: ${props => props.visible ? 12 : 0}px;
    color: ${props => props.theme.text.secondary};
`;

const Right = styled(Frame)`
    width: calc(100% - 300px - 10px);
    margin-left: 10px;
    height: 100%;
    margin-bottom: -20px;
`;

const TableHeader = styled(Frame)`
    width: calc(100% - 300px - 10px - 35px);
    height: 50px;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    position: absolute;
    top: 10px;
    ${props => props.theme.shadow.small}
    backdrop-filter: blur(8px);
    z-index: 2;
`;

const TableBody = styled(Frame)`
    width: 100%;
    height: calc(100%);
    padding-top: 60px;
    display: block;
    overflow-y: scroll;
`;

const Connection = styled(Frame)`
    width: calc(100%);
    height: 50px;
    margin-bottom: 5px;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 12px;
    border: 2px solid ${props => props.any_errors ? props.theme.red : `rgba(0, 0, 0, 0.1)`};
    box-sizing: border-box;
    padding: 10px;
    &:hover { background: rgba(0, 0, 0, 0.05); };
`;

export default ConnectionOverview;
/*eslint-enable*/