/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import moment from 'moment-timezone'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Tab, Input, Dropdown } from '../ui-kit/styled-templates'

import { API_ENDPOINT } from '../../constants/config'
import CommonHelper from '../../utils/common-helper'

import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch';

let usersList = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let user_id = path[1]
    let connection_id = path[3]

    let { users } = useMappedState(useCallback((state) => ({
        users: state.users.usersMap.toArray().filter(i => i.userRole === `franchisee`).map(i => ({ ...i, phones: state.phones.phonesMap.toArray().filter(j => j.userId === i.id || j.sharedUsersEmails.indexOf(i.email) > -1) })),
    }), []))

    let [search, setSearch] = useState(user_id || ``)
    let [upd, setUpd] = useState(+new Date())

    let visibleUsers = users
        .sort((a, b) => b.phones.length - a.phones.length)
        .filter(i => search === `` ? false : (i.email + i.id + (i.telegram || ``) + i.phones.join(``)).replace(` `, ``).replace(`@`, ``).toLowerCase().indexOf(search.replace(`@`, ``).replace(` `, ``).toLowerCase()) > -1)
        .filter((a, b) => b < 100)

    useEffect(() => {
        if (user_id !== undefined && users.get(user_id) !== undefined) {
            let current_history = JSON.parse(localStorage.getItem(`search_history`) || `{}`)
            localStorage.setItem(`search_history`, JSON.stringify({ ...current_history, [users.get(user_id).email]: +new Date() }))
        }
    }, [user_id])

    let searchHistory = Object.entries(JSON.parse(localStorage.getItem(`search_history`) || `{}`)).map((a, b) => ({ email: a[0], lastSeen: a[1], phones: [], id: undefined, ...users.filter(i => i.email === a[0])[0] })).sort((a, b) => b.lastSeen - a.lastSeen)

    return <>
        <SearchWrapper>
            <Input
                image={props => require(`../../assets/WebDesign_v0.1/Icons/search_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}
                clearImage={props => require(`../../assets/WebDesign_v0.1/Icons/close_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}
                extra={props => `width: calc(100% - 30px);`}
                placeholder={`Search`}
                value={search}
                onChange={(e) => { setSearch(e.target.value) }}
            />
            <Dropdown menu={[
                {
                    label: `Logout`,
                    onClick: () => { customHandler(`OPEN_LOGOUT_POP_UP`) },
                },
            ]} >
                <SettingsImage />
            </Dropdown>
        </SearchWrapper>
        { search === `` && searchHistory.length === 0 ? null : <SearchHistoryLabel onClick={() => { localStorage.removeItem(`search_history`); setUpd(+new Date()) }} >Search history</SearchHistoryLabel>}
        {
            (search === `` ? searchHistory : visibleUsers).map((item, index) => {
                return (
                    <UserRow key={index} onClick={() => { CommonHelper.linkTo(`/user/${item.id}`) }} item={item} >
                        <Frame>
                            {item.email}
                        </Frame>
                    </UserRow>
                )
            })
        }
        {users.length === 0 && searchHistory.length === 0 ? <PlaceholderText>Loading</PlaceholderText> : null}
        {users.length > 0 && search === `` && user_id === undefined ? <PlaceholderText>Use search to find a user or connection</PlaceholderText> : null}
        {users.length > 0 && search !== `` && user_id === undefined && visibleUsers.length === 0 ? <PlaceholderText>Nothing found for your request</PlaceholderText> : null}
    </>
}

const SearchHistoryLabel = styled(Frame)`
    width: 100%;
    font-size: 13px;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;

    &:after {
        content: '';
        width: 18px;
        height: 18px;
        background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/close_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
        cursor: pointer;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 3.75vw;

        &:after {
            content: '';
            width: 3.75vw;
            height: 3.75vw;
        }
    }
`;

const PlaceholderText = styled(Frame)`
    font-size: 13px;
    height: calc(100% - 70px);
    margin-top: calc(50vh - 70px);

    @media (display-mode: standalone) {
        /* height: calc(100vh - 70px - 44px); */
    }
`;

const UserRow = styled(Frame)`
    width: calc(100%);
    height: 40px;
    margin-top: 1px;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    &:after {
        content: '${props => props.item.phones.length}';
        width: 20px;
        align-items: flex-start;
        display: flex;
        font-size: 12px;
        color: #a1a1a1;
    };
    &:hover {
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.1); 
    };
    padding: 5px;
    box-sizing: border-box;
    > * {
        &:first-child {
            width: calc(100% - 20px - 10px);
            align-items: flex-start;
            margin: 0 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            color: ${props => props.theme.text.secondary};
        }
    }
`;

const SearchWrapper = styled(Frame)`
    width: 275px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    /* padding: 10px 0 10px 10px; */
    backdrop-filter: blur(8px);

    @media (min-width: 320px) and (max-width: 480px) {
        width: 100%;
    }
`;

const SettingsImage = styled(Frame)`
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;
    &:hover {
        transform: scale(1.2) rotate(90deg);
        transition: 0.2s;
    }
    &:after {
        content: '';
        width: 18px;
        height: 18px;
        background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/settings_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        &:after {
            width: 6vw;
            height: 6vw;
        }
    }

`;

export default usersList;
/*eslint-enable*/