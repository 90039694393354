/*eslint-disable*/

let standalone = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches

let additional = {
    shadow: {
        // small: `box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.05);`,
        small: `box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);`,
        none: `box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);`,
        blue: `box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);`,
        green: `box-shadow: 0px 6px 24px rgba(195, 216, 204, 1);`,
    }
}

let basePalette = {

    red: `#FD6969`,
    yellow: `#FDCA21`,
    green: `#1AB580`,
    blue: `#244EB2`,
    lightblue: `#EAEEF8`,
}

export let dayTheme = {
    ...basePalette,
    ...additional,
    background: {
        primary: `#FFFFFF`,
        secondary: `#6B6B6B`,
        support: `#EFEFEF`,
    },
    text: {
        primary: `#000000`,
        secondary: `#757575`,
        support: `#939393`,
    }
}

export let darkTheme = {
    ...basePalette,
    ...additional,
    background: {
        // primary: standalone ? `#000000` : `#262626`,
        primary: window.innerWidth < 600 ? `#000000` : `#262626`,
        secondary: `#2B2B2B`,
        support: `#858585`,
    },
    text: {
        primary: `#FFFFFF`,
        // secondary: `#FF2F00`,
        secondary: `#7D7D7D`,
        support: `#939393`,
    }
}

export default {
    light: dayTheme,
    dark: darkTheme,
    theme_shift: {
        system: `system`,
        disabled: `scheduled`,
    },
}
/*eslint-enable*/