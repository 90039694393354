/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab } from '../ui-kit/styled-templates'
import UserTab from '../tools/user-tab'
import BalanceTab from '../tools/balance-tab'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let PhonesList = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { phones } = useMappedState(useCallback((state) => {
        let currentUser = { email: ``, ...state.users.usersMap.get(user_id) }
        return ({
            phones: state.phones.phonesMap.toArray().filter(i => i.userId === user_id || i.sharedUsersEmails.indexOf(currentUser.email) > -1),
        })
    }, [path]))

    let [search, setSearch] = useState(``)

    return (
        <>
            <Input
                image={props => require(`../../assets/WebDesign_v0.1/Icons/search_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}
                clearImage={props => require(`../../assets/WebDesign_v0.1/Icons/close_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}
                extra={props => `width: calc(100% - 2px);`}
                placeholder={`Search`}
                value={search}
                onChange={(e) => { setSearch(e.target.value) }}
            />
            <Frame extra={`height: 10px;`} />
            {
                phones.filter(i => search === `` ? true : (i.name + i.id + (i.status.online === true ? `online` : `offline`)).replace(` `, ``).toLowerCase().indexOf(search.replace(` `, ``).toLowerCase()) > -1).map((item, index) => {
                    let any_errors = columns.map(i => i.red === undefined ? false : +i.red(item)).reduce((a, b) => a + b) > 0
                    let is_online = item.status.online === true
                    let loading = item.status.online === undefined
                    return (
                        <Tab
                            key={index}
                            extra={props => `background: ${connection_id === item.id ? props.theme.background.support : props.theme.background.primary}; cursor: pointer !important; &:hover { transform: scale(0.985); }; border: 2px solid ${any_errors ? props.theme.red : `transparent;`};`}
                            onClick={() => { CommonHelper.linkTo(`/user/${user_id}/connection/${item.id}`) }}
                        >
                            <Frame extra={props => `font-size: 13px; color: black; flex-direction: row; &:before { content: ''; width: 8px; height: 8px; border-radius: 50%; background: ${loading ? `rgba(0, 0, 0, 0.05)` : is_online ? props.theme.green : props.theme.red}; margin-right: 5px; };`} >
                                {/* <FollowImage /> */}
                                {item.name}
                            </Frame>
                            <FollowImage />
                        </Tab>
                    )
                })
            }
        </ >
    )
}

const FollowImage = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/up_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    transform: rotate(90deg);
`;

export default PhonesList;
/*eslint-enable*/