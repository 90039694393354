/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab } from '../ui-kit/styled-templates'
import UserTab from '../tools/user-tab'
import BalanceTab from '../tools/balance-tab'
import PhonesList from '../tools/phones-list'
import ConnectionTab from '../tools/connection-tab'
import PhoneStatusTab from '../tools/phone-status-tab'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let ProxyTab = (props) => {

    let { id } = props

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { phones, phone, proxies, plans, user, item } = useMappedState(useCallback((state) => {
        let currentUser = { email: ``, ...state.users.usersMap.get(user_id) }
        return ({
            phones: state.phones.phonesMap.toArray().filter(i => i.userId === user_id || i.sharedUsersEmails.indexOf(currentUser.email) > -1),
            phone: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) },
            proxies: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) }.proxies,
            plans: state.plans.plansMap.toArray(),
            user: currentUser,
            item: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) }.proxies.get(id),
        })
    }, [path]))
    let any_errors = columns.map(i => i.red === undefined ? false : +i.red(phone)).reduce((a, b) => a + b) > 0
    let currentPlan = plans !== undefined && phone.activePlans !== undefined && plans.length > 0 && phone.activePlans.length > 0 ? phone.activePlans[0] : { id: `-`, startTimestamp: +moment(), expirationTimestamp: +moment().add(1, `month`) }

    return (
        <Tab>
            <Frame extra={`width: 100%; flex-direction: row; justify-content: space-between; > * { font-size: 12px !important; };`} >
                <Frame extra={`width: 10%;`} >{item.listen_service}</Frame>
                <Frame extra={`width: 30%;`} >{item.address}</Frame>
                <Frame extra={`width: 15%;`} >{item.port}</Frame>
                <Frame extra={`width: 40%;`} >{item.auth_type === `noauth` ? `` : item.login}</Frame>
                <Frame extra={`width: 10%;`} >{item.auth_type === `noauth` ? `` : item.password}</Frame>
                {/* <DeleteButton /> */}
            </Frame>
            <ButtonsWrapper>
                <LocalButton
                    onClick={async () => {
                        await navigator.clipboard.writeText(getProxyAccessURL(item));
                    }}
                >
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/copy_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                        Copy
                    </LocalButton>
                <LocalButton
                    onClick={() => {
                        ipcRenderer.send(`open-puppeteer`, getProxyAccessURL(item))
                    }}
                >
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/data_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                        Test
                    </LocalButton>
                <LocalButton>
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/delete_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                    <Dropdown menu={[
                        {
                            label: `Confirm`,
                            onClick: async () => {
                                let start = +moment()
                                // await axios.post(`${API_ENDPOINT}/connections/smart_delete`, { id })
                                // console.log(`> > > delete`);
                                // let connections = (await axios.get(`${API_ENDPOINT}/user/${user_id}/connections`)).data
                                // console.log(`> > > loaded new proxy`);
                                // dispatch(phonesActions.loadVisibleProxiesSuccess(connections))
                                dispatch(phonesActions.deleteProxy(id))
                                // dispatch(phonesActions.loadVisibleProxies([phone]))
                                console.log(+moment().diff(start));
                            },
                            extra: `color: ${props => props.theme.yellow};`
                        },
                    ]} >
                        Delete
                    </Dropdown>
                </LocalButton>
            </ButtonsWrapper>
        </Tab>
    )
}

const Title = styled(Frame)`
    width: 100%;
    align-items: flex-start;
    font-size: 24px;
    color: ${props => props.theme.text.primary} !important;
    font-weight: bold;
    margin-bottom: 10px;
`;

const FollowImage = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/up_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    transform: rotate(90deg);
`;

const LocalButton = styled(Frame)`
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: regular;
    font-size: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    margin-right: 5px;
    cursor: pointer;
    position: relative;

    &:hover {
        /* opacity: 0.8;
        transform: scale(0.95); */
    };
`;

const ButtonsWrapper = styled(Frame)`
    width: 100%;
    flex-direction: row;
    margin-top: 15px;
    > * {
        &:last-child {
            border-right: 0px;
            margin-right: 0px;
        }
    }
`;

export default ProxyTab;
/*eslint-enable*/