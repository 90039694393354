/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import useOnClickOutside from '../../hooks/useOnClickOutside'

let Dropdown = (props) => {

    let { menu = [] } = props

    let [visible, setVisible] = useState(false)
    let [confirm, setConfirm] = useState(undefined)

    let ref = useRef()
    useOnClickOutside(ref, () => { setVisible(false) })

    useEffect(() => { setConfirm(undefined) }, [visible])

    return (
        <Frame extra={props.extra} >
            <Frame onClick={() => { setVisible(true) }} extra={`cursor: pointer;`} >
                {props.children}
            </Frame>
            <BlurredBackground id={`blurred-background`} visible={visible} onClick={(e) => { if (e.target.id === `blurred-background`) { setVisible(false) } }} >
                <DroppedFrame ref={ref} visible={visible} >
                    {
                        props.custom
                            ? props.custom
                            : menu.map((item, index) => {
                                return (
                                    <Item key={index} {...item} onClick={() => {
                                        if (item.confirm === true) {
                                            setConfirm(index)
                                        } else {
                                            if (item.onClick !== undefined) {
                                                item.onClick()
                                                setVisible(false)
                                            }
                                        }
                                    }} >
                                        {
                                            confirm === index ? <Confirm onClick={() => {
                                                if (props.onClick !== undefined) {
                                                    props.onClick()
                                                }
                                            }} /> : item.label
                                        }
                                    </Item>
                                )
                            })
                    }
                </DroppedFrame>
            </BlurredBackground>
        </Frame>
    )
}

const Frame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* position: relative; */
    transition: 0.2s;
    ${props => props.extra}
`;

const DroppedFrame = styled(Frame)`
    position: absolute;
    background: ${props => props.theme.name === `light` ? props.theme.background.primary : props.theme.background.secondary};
    /* backdrop-filter: blur(8px); */
    ${props => props.theme.shadow.small}
    border-radius: 12px;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => props.visible ? 1 : 0};
    align-items: flex-end;
    min-width: 100px;
    transform: translate(calc(-50% - 6px), calc(50% - ${props => props.visible ? 10 : 25}px));
    z-index: 2;
    /* height: ${props => props.visible ? `auto` : `0px`}; */
    > * {
        margin-bottom: 10px;
        transform: scale(${props => props.visible ? 1 : 0.8});
        &:last-child {
            margin-bottom: 0px;
        }
    }

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        /* width: 90vw; */
        /* top: 50%; */
        /* left: 50%; */
        /* transform: translate(-50%, -50%); */
        /* position: fixed; */
        /* bottom: 2vw; */
        /* left: 2vw; */
        top: none;
        right: 2vw;
        bottom: 30vh;
        transform: none;
        align-items: flex-end;
    }
`;

const Item = styled(Frame)`
    font-size: 14px;
    color: ${props => props.color || props.theme.text.secondary};
    font-weight: ${props => props.color ? `bold` : `500`};
    cursor: ${props => props.disabled ? `default` : `pointer`};
    opacity: ${props => props.disabled ? 0.3 : 1};
    &:hover {
        opacity: ${props => props.disabled ? 0.3 : 0.8};
        transform: translateX(${props => props.disabled ? 0 : -2}px);
    }
    ${props => props.extra}

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 3.5vw;
        line-height: 6.5vw;
    }
`;

const Confirm = styled(Frame)`
    &:after {
        content: 'Confirm';
        color: ${props => props.theme.yellow};
    }
`;

const BlurredBackground = styled(Frame)`
    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(${props => props.visible ? 8 : 0}px);
        background: rgba(0, 0, 0, ${props => props.visible ? 0.5 : 0});
        visibility: ${props => props.visible ? `visible` : `hidden`};
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        box-sizing: border-box;
        > * {
            transition: 0.2s;
            opacity: ${props => props.visible ? 1 : 0};
        }
    }
`;

export default Dropdown;
/*eslint-enable*/