/*eslint-disable*/
import * as types from '../ActionTypes'
import PhonesAPI from '../../api/PhonesAPI';

//PHONES
let loadPhones_ = () => {
    return {
        type: types.LOAD_PHONES
    }
}

let loadPhonesFail = (error) => {
    return {
        type: types.LOAD_PHONES_FAIL,
        error: error
    }
}

let loadPhonesSuccess = (phones, links) => {
    return {
        type: types.LOAD_PHONES_SUCCESS,
        phones: phones
    }
}

//load all phones
export function loadAllPhones() {
    return (dispatch, getState) => {
        dispatch(loadPhones_())
        return PhonesAPI.getAllPhones().then(
            phones => dispatch(loadPhonesSuccess(phones)),
            err => dispatch(loadPhonesFail(err))
        )
    }
}

//SERVERS
let loadServers_ = () => {
    return {
        type: types.LOAD_SERVERS
    }
}

let loadServersFail = (error) => {
    return {
        type: types.LOAD_SERVERS_FAIL,
        error: error
    }
}

let loadServersSuccess = (servers, links) => {
    return {
        type: types.LOAD_SERVERS_SUCCESS,
        servers: servers
    }
}

//load all servers
export let loadAllServers = () => {
    return (dispatch, getState) => {
        dispatch(loadServers_())
        return PhonesAPI.loadServers().then(
            servers => dispatch(loadServersSuccess(servers)),
            err => dispatch(loadServersFail(err))
        )
    }
}

//CONNECTIONS
let loadVisibleProxies_ = () => {
    return {
        type: types.LOAD_PROXIES
    }
}

let loadVisibleProxiesFail = (error) => {
    return {
        type: types.LOAD_PROXIES_FAIL,
        error: error
    }
}

export let loadVisibleProxiesSuccess = (connections) => {
    return {
        type: types.LOAD_PROXIES_SUCCESS,
        connections: connections
    }
}

//load all connections
export function loadVisibleProxies(visiblePhones) {
    let localUsers = visiblePhones.map(i => i.userId).unique()
    return (dispatch, getState) => {
        dispatch(loadVisibleProxies_())
        return PhonesAPI.getVisibleProxies(localUsers).then(
            connections => dispatch(loadVisibleProxiesSuccess(connections)),
            err => dispatch(loadVisibleProxiesFail(err))
        )
    }
}

//load all connections
export function loadProxiesForUser(user_id) {
    return (dispatch, getState) => {
        dispatch(loadVisibleProxies_())
        return PhonesAPI.getProxiesForUser(user_id).then(
            connections => dispatch(loadVisibleProxiesSuccess(connections)),
            err => dispatch(loadVisibleProxiesFail(err))
        )
    }
}

//STATUSES
let loadVisibleStatuses_ = () => {
    return {
        type: types.LOAD_STATUSES
    }
}

let loadVisibleStatusesFail = (error) => {
    return {
        type: types.LOAD_STATUSES_FAIL,
        error: error
    }
}

let loadVisibleStatusesSuccess = (statuses) => {
    return {
        type: types.LOAD_STATUSES_SUCCESS,
        statuses: statuses
    }
}

//load all statuses
export function loadVisibleStatuses(visibleUsers) {
    return (dispatch, getState) => {
        dispatch(loadVisibleStatuses_())
        return PhonesAPI.getVisibleStatuses(visibleUsers).then(
            statuses => dispatch(loadVisibleStatusesSuccess(statuses)),
            err => dispatch(loadVisibleStatusesFail(err))
        )
    }
}

//load users statuses
export function loadStatusesForUser(user_id) {
    return (dispatch, getState) => {
        dispatch(loadVisibleStatuses_())
        return PhonesAPI.getStatusesForUser(user_id).then(
            statuses => dispatch(loadVisibleStatusesSuccess(statuses)),
            err => dispatch(loadVisibleStatusesFail(err))
        )
    }
}

//UPDATE PHONE
let updatePhone_ = () => {
    return {
        type: types.UPDATE_PHONE
    }
}

let updatePhoneFail = (error) => {
    return {
        type: types.UPDATE_PHONE_FAIL,
        error: error
    }
}

export let updatePhoneSuccess = (phone) => {
    return {
        type: types.UPDATE_PHONE_SUCCESS,
        phone: phone
    }
}

export function updatePhone(data) {
    return (dispatch, getState) => {
        dispatch(updatePhone_())
        return PhonesAPI.updatePhone(data).then(
            phone => dispatch(updatePhoneSuccess(phone)),
            err => dispatch(updatePhoneFail(err))
        )
    }
}

//DELETE PHONE
let deletePhone_ = () => {
    return {
        type: types.DELETE_PHONE
    }
}

let deletePhoneFail = (error) => {
    return {
        type: types.DELETE_PHONE_FAIL,
        error: error
    }
}

let deletePhoneSuccess = (id) => {
    return {
        type: types.DELETE_PHONE_SUCCESS,
        id: id
    }
}

export function deletePhone(id) {
    return (dispatch, getState) => {
        dispatch(deletePhone_())
        return PhonesAPI.deletePhone(id).then(
            phone => dispatch(deletePhoneSuccess(id)),
            err => dispatch(deletePhoneFail(err))
        )
    }
}

//CREATE PROXY
let createProxy_ = () => {
    return {
        type: types.CREATE_PROXY
    }
}

let createProxyFail = (error) => {
    return {
        type: types.CREATE_PROXY_FAIL,
        error: error
    }
}

let createProxySuccess = (proxy) => {
    return {
        type: types.CREATE_PROXY_SUCCESS,
        proxy: proxy
    }
}

export function createProxy(data) {
    return (dispatch, getState) => {
        dispatch(createProxy_())
        return PhonesAPI.createProxy(data).then(
            proxy => dispatch(createProxySuccess(proxy)),
            err => dispatch(createProxyFail(err))
        )
    }
}

//DELETE PROXY
let deleteProxy_ = (id) => {
    return {
        type: types.DELETE_PROXY,
        id: id,
    }
}

let deleteProxyFail = (error) => {
    return {
        type: types.DELETE_PROXY_FAIL,
        error: error
    }
}

let deleteProxySuccess = (id) => {
    return {
        type: types.DELETE_PROXY_SUCCESS,
        id: id
    }
}

export function deleteProxy(id) {
    return (dispatch, getState) => {
        dispatch(deleteProxy_())
        return PhonesAPI.deleteProxy(id).then(
            id => dispatch(deleteProxySuccess(id)),
            err => dispatch(deleteProxyFail(err))
        )
    }
}

/*eslint-enable*/