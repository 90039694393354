/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown } from '../ui-kit/styled-templates'
import TablePage from '../pages/table-page'
import DetailsPage from '../pages/details-page'
import SinglePage from '../pages/single-page'

import LogOutPopUp from '../pop-ups/logout-pop-up.js'
import CreateProxyPopUp from '../pop-ups/create-proxy-pop-up.js'

import CommonHelper from '../../utils/common-helper'

import useKeyHandler from '../../hooks/useKeyHandler'

let AdminApp = (props) => {

    let dispatch = useDispatch()
    useEffect(() => {
        dispatch(phonesActions.loadAllPhones())
        dispatch(phonesActions.loadAllServers())
        dispatch(plansActions.loadAllPlans())
        dispatch(usersActions.loadAllUsers())
    }, [])

    useKeyHandler(`Escape`, () => { CommonHelper.linkTo(`/`) })

    return (
        <Wrapper>

            <LogOutPopUp />
            <CreateProxyPopUp />

            <SplashScreen />

            <Switch>
                <Route path={`/user/:user_id`} exact component={SinglePage} />
                <Route path={`/user/:user_id/connection/:connection_id`} exact component={SinglePage} />
                <Route exact component={SinglePage} />
            </Switch>

        </Wrapper >
    )
}

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    flex-direction: row;
    padding: 0 10px;
    box-sizing: border-box;
    align-items: flex-start;

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 0px;
        height: auto;
        /* height: calc(100vh - 44px - 70px); */
    }

    @media (display-mode: standalone) {
        
    }
`;

const BlurredBackground = styled(Frame)`
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(${props => props.visible ? 8 : 0}px);
    background: rgba(0, 0, 0, ${props => props.visible ? 0.5 : 0});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    > * {
        transition: 0.2s;
        opacity: ${props => props.visible ? 1 : 0};
    }
`;

const PWAMenu = styled(Frame)`
    display: none;

    @media (display-mode: standalone) {
        display: flex;
        width: 90vw;
        height: 20vw;
        border-radius: 6vw;
        background: rgba(255, 255, 255, 0.1);
        position: fixed;
        bottom: 10vw;
        z-index: 2;
        backdrop-filter: blur(6px);
        border: 1px solid rgba(0, 0, 0, 0.05);
        ${props => props.theme.shadow.small};
    }
`;

let SplashScreen = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let user_id = path[1]
    let connection_id = path[3]

    let { loading } = useMappedState(useCallback((state) => ({
        loading: state.users.usersMap.toArray().length === 0 || state.phones.phonesMap.toArray().length === 0 || (
            user_id ? [``, undefined, null].indexOf({ ...state.users.usersMap.get(user_id) }.email) > -1 : false
        )
    }), []))

    return (
        <BlurredBackground visible={loading} >
            <Logo />
            <Frame extra={`color: white;`} >
                Loading...
            </Frame>
        </BlurredBackground>
    )
}

const Logo = styled(Frame)`
    width: 10vw;
    height: 10vw;
    background: url("${require(`../../assets/images/favicon.svg`).default}") no-repeat center center / contain;

    @media (min-width: 320px) and (max-width: 480px) {
        width: 45vw;
        height: 45vw;
    }
`;

export default AdminApp;
/*eslint-enable*/