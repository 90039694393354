/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab } from '../ui-kit/styled-templates'
import UserTab from '../tools/user-tab'
import BalanceTab from '../tools/balance-tab'
import PhonesList from '../tools/phones-list'
import ConnectionTab from '../tools/connection-tab'
import PhoneStatusTab from '../tools/phone-status-tab'
import ProxyTab from '../tools/proxy-tab'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let DetailsPage = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { phones, phone, proxies, plans, user } = useMappedState(useCallback((state) => {
        let currentUser = { email: ``, ...state.users.usersMap.get(user_id) }
        return ({
            phones: state.phones.phonesMap.toArray().filter(i => i.userId === user_id || i.sharedUsersEmails.indexOf(currentUser.email) > -1),
            phone: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) },
            proxies: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) }.proxies,
            plans: state.plans.plansMap.toArray(),
            user: currentUser,
        })
    }, [path]))

    return (
        <>
            <Frame extra={props => `width: 350px; margin: 10px 0 0 10px; height: 100vh; display: block; overflow-y: scroll; * { color: ${props.theme.background.secondary} };`} >
                <Title>User</Title>
                <UserTab />
                <Frame extra={`width: 100%; align-items: flex-start; font-size: 24px; color: black !important; font-weight: bold; margin-bottom: 10px;`} >Balance</Frame>
                <BalanceTab />
            </Frame>
            <Frame extra={`width: calc(100% - 350px - 350px - 450px); margin-left: 10px;`} >

            </Frame>
            <Frame extra={props => `width: 350px; padding: 20px 0 0 0; margin: -10px 0 0 10px; height: 100vh; display: block; overflow-y: scroll; * { color: ${props.theme.background.secondary} };`} >
                <Title>Phones</Title>
                <PhonesList />
            </Frame>
            <Frame extra={props => `width: 450px; padding: 20px 0 0 0; margin: -10px 0 0 10px; height: 100vh; display: block; overflow-y: scroll; * { color: ${props.theme.background.secondary} };`} >
                <Switch>
                    <Route path={`/user/:user_id`} exact component={() => null} />
                    <Route path={`/user/:user_id/connection/:connection_id`} exact component={() => <>
                        <Title>Connection</Title>
                        <ConnectionTab />
                        <PhoneStatusTab />
                        <Title>Proxy</Title>
                        {
                            proxies.filter(i => i.phoneId === connection_id).map((item, index) => <ProxyTab key={index} id={item.id} />)
                        }
                        <Button extra={`font-size: 13px; cursor: pointer; margin-bottom: 15px;`} onClick={() => {
                            customHandler('OPEN_CREATE_PROXY_POP_UP')
                        }} >
                            + add proxy access
                        </Button>
                    </>} />
                </Switch>
            </Frame>
        </ >
    )
}

const Title = styled(Frame)`
    width: 100%;
    align-items: flex-start;
    font-size: 24px;
    color: ${props => props.theme.text.primary} !important;
    font-weight: bold;
    margin-bottom: 10px;
`;

export default DetailsPage;
/*eslint-enable*/