/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import moment from 'moment-timezone'
import axios from 'axios'

import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Tab } from '../ui-kit/styled-templates'

import { API_ENDPOINT } from '../../constants/config'

import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch'

let TransactinosList = (props) => {

    let { user_id } = props

    let { phones } = useMappedState(useCallback((state) => ({
        phones: state.phones.phonesMap.toArray().filter(i => i.userId === user_id)
    }), []))

    let [transactions, setTransactions] = useState([])

    useCustomDispatch(`LOAD_TRANSACTIONS`, () => {
        let mounted = true
        setTransactions([])
        if (user_id !== undefined) {
            axios.get(`${API_ENDPOINT}/user/${user_id}/transactions`).then((d) => { if (mounted) { setTransactions(d.data) } })
        }
        return () => mounted = false
    })

    useEffect(() => {
        customHandler(`LOAD_TRANSACTIONS`)
    }, [user_id])

    return (transactions || []).sort((b, a) => a.timestamp - b.timestamp).map((item, index) => {
        return (
            <Tab key={index} extra={`* { font-size: 12px; }; align-items: center !important;`} >
                {/* <Frame>{item.amount > 0 ? `In` : `Out`}come</Frame> */}
                <Frame extra={`align-items: flex-start;`} >
                    {
                        item.type === `REPLENISHMENT`
                            ? <Frame extra={props => `font-size: 16px; color: ${props.theme.text.primary};`} >REPLENISHMENT</Frame>
                            : <>
                                <Frame>{item.description}</Frame>
                                <Frame>{{ name: ``, ...phones.get(item.phoneId) }.name}</Frame>
                            </>
                    }
                </Frame>
                <Frame extra={`align-items: flex-end;`} >
                    <Frame>{moment(item.timestamp).format(`DD.MM.YY HH:mm`)}</Frame>
                    <Frame extra={props => `color: ${item.type === `REPLENISHMENT` && item.amount >= 0 ? props.theme.green : item.type === `PAYMENT` || item.amount < 0 ? props.theme.red : props.theme.text.primary} !important; font-size: 16px;`} >{item.amount}₽</Frame>
                </Frame>
            </Tab>
        )
    })
}

export default TransactinosList;
/*eslint-enable*/