/*eslint-disable*/
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { HashRouter, Switch, Route, withRouter, useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook';

import * as usersActions from '../../redux/actions/UsersActions'

import { ThemeWrapper } from '../ui-kit/styled-templates';
import GuestApp from "./guest-app";
import AdminApp from "./admin-app";

import useCurrentUser from '../../hooks/useCurrentUser'

let RouterApp = () => {

    const { initialized } = useMappedState(useCallback(state => ({
        initialized: state.users.initialized
    }), []));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usersActions.initializeAuthorization())
    }, [])

    let { userRole } = useCurrentUser()

    let route = GuestApp;
    switch (userRole) {
        case `guest`: route = GuestApp; break;
        case `admin`: route = AdminApp; break;
        default: route = GuestApp; break;
    }

    if (!initialized) {
        return null
    }

    return (
        <ThemeWrapper>
            <HashRouter>
                <Wrapper>
                    {/* <IOSStatusBarPlaceholder/> */}
                    {/* <PWAStatusBarPlaceholder /> */}
                    <Switch>
                        <Route component={route} />
                    </Switch>
                </Wrapper>
            </HashRouter>
        </ThemeWrapper>
    );
}

const Wrapper = styled.div`
    /* background: rgba(0, 0, 0, 0.05); */
    background: ${props => props.theme.background.primary};

    ${props => {
        document.body.style.background = props.theme.background.primary;
        return ``
    }}

    @media only screen and (max-width: 600px) {
        
    }

    @media (display-mode: standalone) {
        /* ${props => {
            if (props.theme.text.primary === `#FFFFFF`) {
                // document.body.style.background = `#000000`;
            }
            return `
                background: ${props.theme.text.primary === `#FFFFFF` ? `#000000` : `#FFFFFF`};
            `
        }} */
    }
`;

const IOSStatusBarPlaceholder = styled.div`
    display: none;
    width: 100%;
    height: 0px;

    @supports (-webkit-touch-callout: none) {
        display: flex;
    }
`;

const PWAStatusBarPlaceholder = styled.div`
    display: none;
    width: 100%;
    height: 44px;

    @media (display-mode: standalone) {
        display: flex;    
    }
`;

export default RouterApp;
/*eslint-enable*/
