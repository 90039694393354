/*eslint-disable*/
import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

let Calendar = (props) => {

    let { onChange = () => { }, selectedDate = +moment().add(-100, `years`) } = props

    let [monthStart, setMonthStart] = useState(+moment().startOf(`month`))

    return (
        <Wrapper>
            <Frame extra={`width: 100%; flex-direction: row; justify-content: space-between; margin-bottom: 5px;`} >
                <ArrowBack onClick={() => { setMonthStart(+moment(monthStart).add(-1, `month`)) }} />
                <Frame onClick={() => { setMonthStart(+moment().startOf(`month`)) }} >
                    {moment(monthStart).format(`MMMM`)}
                </Frame>
                <ArrowForward onClick={() => { setMonthStart(+moment(monthStart).add(1, `month`)) }} />
            </Frame>
            <Frame extra={`width: 100%; flex-direction: row; flex-wrap: wrap; justify-content: space-between;`} >
                {
                    new Array(35).fill(0).map((item, index) => +moment(monthStart).startOf(`month`).startOf(`isoWeek`).add(index, `days`)).map((item, index) => {
                        return (
                            <Day
                                key={index}
                                thisMonth={monthStart === +moment(item).startOf(`month`)}
                                isToday={+moment().startOf(`day`) === item}
                                onClick={() => { onChange(item) }}
                                isSelectedDate={+moment(selectedDate).startOf(`day`) === item}
                            >
                                {moment(item).format(`DD`)}
                            </Day>
                        )
                    })
                }
            </Frame>
        </Wrapper>
    )
}

const Frame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: 0.2s;
    ${props => props.extra}
`;

const ArrowBack = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/up_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    transform: rotate(-90deg);
`;

const ArrowForward = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/up_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    transform: rotate(90deg);
`;

const Day = styled(Frame)`
    width: calc(200px / 7);
    height: calc(200px / 7);
    opacity: ${props => props.thisMonth || props.isToday ? 1 : 0.5};
    border-radius: 50%;
    color: ${props => props.isToday ? `white` : props.theme.text.secondary} !important;
    background: ${props => props.isToday ? props.theme.red : `transparet`};
    box-sizing: border-box;
    border: ${props => props.isSelectedDate ? 2 : 0}px solid rgba(${props => props.theme.name === `dark` ? `255, 255, 255` : `0, 0, 0`}, 0.1);
    &:hover {
        background: ${props => props.isToday ? props.theme.red : props.theme.background.support};
    }

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        /* font-size:  */
        width: calc(70vw / 7);
        height: calc(70vw / 7);
    }
`;

const Wrapper = styled(Frame)`
    width: 200px;
    * { font-size: 12px; }

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 70vw;
        * { font-size: 3.5vw; };
    }
`;

export default Calendar;
/*eslint-enable*/