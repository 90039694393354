/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import mvConsts from '../../constants/mvConsts'
import InputMask from 'react-input-mask';
import { Frame, convertHex } from './styled-templates'

import useCustomDispatch from '../../hooks/useCustomDispatch'

export default (props) => {
    let ref = useRef()
    let [focus, setFocus] = useState(false)
    let [error, setError] = useState(false)
    useEffect(() => {
        let focusHandler = (event) => { setFocus(true) }
        let blurHandler = (event) => { setFocus(false) }
        ref.current.addEventListener(`focus`, focusHandler, true);
        ref.current.addEventListener(`blur`, blurHandler, true);
        return (() => {
            if (ref.current) {
                ref.current.removeEventListener(`focus`, focusHandler, true);
                ref.current.removeEventListener(`blur`, blurHandler, true);
            }
        })
    }, [])
    let hasImage = props.image !== undefined
    useCustomDispatch(`CALL_INPUT_ERROR`, (e) => { if (e.detail === props.placeholder) { setError(true); setTimeout(() => { setError(false) }, [1000]) } })

    return (
        <Wrapper extra={props.extra} >
            {props.type !== `range` && props.placeholder !== undefined ? <Label focus={focus || (props.value || ``).length > 0} hasImage={hasImage} onClick={() => { ref.current.focus() }}>{props.placeholder}</Label> : null}
            {props.image ? <Image image={props.image} visible={!(focus || props.value.length > 0)} /> : null}
            {props.clearImage ? <ClearImage onClick={() => { if (props.onChange !== undefined) { props.onChange({ target: { value: `` } }) } }} image={props.clearImage} visible={(focus || props.value.length > 0)} /> : null}
            {
                props.textarea
                    ? <Textarea ref={ref} {...props} error={error} />
                    : <Input ref={ref} {...props} error={error} />
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`

    ${props => props.extra}
    background: transparent !important;
    position: relative !important;
`;

const Label = styled.div`
    display: flex;
    position: absolute;
    box-sizing: border-box;
    top: ${props => props.focus ? -10 : 14}px;
    left: ${props => +(props.hasImage && !props.focus) * 16 + 17}px;
    font-size: ${props => props.focus ? 12 : 14}px;
    transition: 0.1s;
    background: ${props => props.focus ? props.theme.background.primary : `transparent`};
    padding: 2px 8px;
    border-radius: 8px;
    color: ${props => props.theme.text.secondary} !important;
    cursor: text;

    @media only screen and (max-width: 600px) {
        z-index: 0;
    }
`;

const Image = styled.div`
    display: flex;
    position: absolute;
    left: 17px;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: scale(${props => +props.visible}) translateY(-50%);
    opacity: ${props => +props.visible};
    transition: 0.2s;
    background: url(${props => props.image}) no-repeat center center / contain;
`;

const ClearImage = styled(Image)`
    right: 17px;
    left: auto;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        opacity: 0.75;
    }
`;

const Textarea = styled.textarea`
    -webkit-appearance: none;
    outline: none;
    width: ${props => (props.short ? 255 : 540) - 34}px;
    resize: none; 
    min-height: calc(100px - 34px);
    font-size: 14px;
    padding: 17px;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.secondary};
    border-radius: 5px;
    color: ${props => props.theme.text.primary};
    transition: 0.2s;
    ::placeholder { color: transparent; }
    &::-webkit-input-placeholder {
        ${props => props.placeholderColor === undefined ? null : `color: ${props => props.placeholderColor}`}
    }

    @media (min-width: 320px) and (max-width: 480px) {
        width: calc(90vw - 34px);
        height: calc(31.25vw - 2 * 3.75vw);
        padding: 3.75vw 4.5vw;
        display: flex;
    }

    @supports (-webkit-touch-callout: none) {
        
    }
    
    ${props => props.extra}
`

const Input = styled.input`
    -webkit-appearance: none;
    outline: none;
    width: ${props => (props.short ? 255 : 540) - 34}px;
    height: ${48 - 34}px;
    font-size: 14px;
    padding: 17px;
    transition: 0.2s;

    width: calc(300px - 34px - 30px);
    border-radius: 30px;
    background: ${props => props.theme.text.primary === `#FFFFFF` ? `transparent` : props.theme.background.support};
    border: ${props => props.theme.text.primary === `#FFFFFF` ? 2 : 0}px solid ${props => props.theme.text.secondary};
    color: ${props => props.theme.text.secondary} !important;

    ::-webkit-slider-thumb {
        /* border: 0.1vw solid #0ff050; */
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        -webkit-appearance: none;
    }
    ::placeholder {
        color: transparent;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        width: calc(90vw - 34px);
        height: ${props => +props.visible * 5}vw;
        padding: ${props => +props.visible * 4}vw;
        border-radius: 10vw;
        margin: ${props => +props.visible}vw;
        font-size: 4vw;
    }
    &::-webkit-input-placeholder {
        ${props => props.placeholderColor === undefined ? null : `color: ${props => props.placeholderColor}`}
    }
    
    ${props => props.extra}

    width: calc(100% - 16px - 20px) !important;
`
/*eslint-enable*/