/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT, LOGIN_ENDPOINT } from "../constants/config";

const UsersAPI = {

    async parselessLogout() {
        try {
            window.localStorage.removeItem(`auth_token`);
        } catch (exc) {
            throw new Error(exc);
        }
    },

    async parselessLogin(email, password, rememberMe = true) {
        try {
            let pld = (await axios.post(`${LOGIN_ENDPOINT}/users/login`, { email: `${email}`.toLowerCase(), password })).data;
            if (pld.error !== undefined) {
                console.log(pld.error);
                throw pld.error;
            }
            let { token } = pld;
            let us = (await axios.get(`${API_ENDPOINT}/user/token/${token}`)).data
            if (us.userRole !== `admin`) {
                let message = `Sorry, this page is only for admins`
                throw ({ message })
            }
            if (rememberMe) {
                window.localStorage.setItem(`auth_token`, token);
            }
            return us;
        } catch (exc) {
            window.alert(exc.message)
            throw exc;
        }
    },

    async parselessSignup(email, password) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/users/signup`, { email, password })).data;
            if (pld.error !== undefined) {
                throw pld.error;
            }
            let { token } = pld.result;
            window.localStorage.setItem(`auth_token`, token);
            return (await axios.get(`${API_ENDPOINT}/user/token/${token}`)).data;
        } catch (exc) {
            throw new Error(exc);
        }
    },

    async parselesslyGetCurrentUser() {
        let token = window.localStorage.getItem(`auth_token`);
        let pld = (await axios.get(`${API_ENDPOINT}/user/token/${token}`)).data;
        if (pld.error !== undefined) {
            return undefined;
        }
        return pld;
    },

    createUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/create`, data).then(d => d.data).then(
                user => {
                    if (user.error !== undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    getUsersByIds(ids) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/by_ids`, { ids: ids }).then(d => d.data).then(users => {
                if (users.error !== undefined) {
                    return reject(users.error);
                }
                resolve(users);
            }).catch(err => reject(err));
        });
    },


    updateUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/update`, data).then(d => d.data).then(
                pld => {
                    if (pld.error !== undefined) {
                        return reject(pld.error);
                    }
                    resolve(pld.result);
                }
            ).catch(err => reject(err))
        })
    },

    deleteUser(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/delete/${id}`).then(d => d.data).then(
                () => resolve(id)
            ).catch(err => reject(err))
        });
    },

    getUsersByQueryMap(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/query`, {
                query: queryMap
            }).then(d => d.data).then(
                users => resolve(users)
            ).catch(err => reject(err))
        });
    },

    getAllUsers(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/users/all`).then(d => d.data).then(
                users => {
                    resolve(users);
                }
            ).catch(err => reject(err))
        });
    },

    getUserById(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}`).then(d => d.data).then(user => {
                if (user.error !== undefined) {
                    return reject(user.error);
                }
                resolve(user);
            }).catch(err => {
                reject(err);
            })
        })
    },

    getBalanceForUser(userId) {
        return new Promise(async (resolve, reject) => {
            try {
                let balance = (await axios.get(`${API_ENDPOINT}/user/${userId}/balance`)).data.balance
                resolve(balance)
            } catch (error) {
                reject(error)
            }
        })
    },

    getTransactionsForUser(userId) {
        return new Promise(async (resolve, reject) => {
            try {
                let transactions = (await axios.get(`${API_ENDPOINT}/user/${userId}/transactions`)).data
                resolve(transactions)
            } catch (error) {
                reject(error)
            }
        })
    },

}

export default UsersAPI;

/*eslint-enable*/