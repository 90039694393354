/*eslint-disable*/
import React, { useCallback } from 'react'
import { useMappedState } from 'redux-react-hook'
import moment from 'moment-timezone';

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

export let getProxyAccessURL = (item) => {
    return `${item.listen_service}://${item.auth_type === `userpass` ? `${item.login}:${item.password}@` : ``}${item.address}:${item.port}`
}

let useConnectionParams = (id = ``) => {

    let { email, plans } = useMappedState(useCallback((state) => {
        return ({
            email: { email: ``, ...state.users.usersMap.get({userId: ``, ...state.phones.phonesMap.get(id)}.userId) }.email,
            plans: state.plans.plansMap.toArray(),
        })
    }, []))

    return [
        {
            width: 100,
            name: `ID`,
            value: (item) => item.id,
            green: (item) => false,
            red: (item) => false,
            extra: ``,
            only_table: true,
        },
        {
            width: 150,
            name: `Email`,
            value: (item) => email,
            green: (item) => false,
            red: (item) => false,
            extra: ``,
            only_table: true,
        },
        {
            width: 100,
            name: `Connection`,
            value: (item) => item.name === undefined || item.name === `` ? `NO NAME` : item.name,
            green: (item) => false,
            red: (item) => false,
            yellow: (item) => item.name === undefined || item.name === ``,
            extra: ``,
            only_table: true,
        },
        {
            width: 75,
            name: `Phone`,
            value: (item) => item.deviceModel || `no phone`,
            green: (item) => false,
            red: (item) => item.deviceModel === undefined && item.deviceModel !== ``,
            yellow: (item) => (item.deviceModel || ``).toLowerCase().indexOf(`meizu`) !== -1,
            extra: ``,
        },
        {
            width: 30,
            name: `Ver`,
            value: (item) => item.appVersion,
            green: (item) => +item.appVersion === 115,
            yellow: (item) => +item.appVersion < 100,
            red: (item) => false,
            extra: ``,
        },
        {
            width: 50,
            name: `ROOT`,
            value: (item) => `ROOT`,
            green: (item) => item.isRoot === true,
            red: (item) => false,
            extra: ``,
        },
        {
            width: 30,
            name: `MD`,
            value: (item) => `MD`,
            green: (item) => item.macrosUrl !== undefined && item.macrosUrl !== ``,
            red: (item) => false,
            extra: ``,
        },
        {
            width: 30,
            name: `FBT`,
            value: (item) => `FBT`,
            green: (item) => item.fireBaseToken !== undefined,
            red: (item) => false,
            yellow: (item) => item.fireBaseToken === undefined && item.fireBaseToken !== ``,
            extra: ``,
        },
        {
            width: 30,
            name: `DNS`,
            value: (item) => `DNS`,
            green: (item) => false,
            red: (item) => false,
            yellow: (item) => (item.dnsIpList || []).length > 0,
            extra: ``,
        },
        {
            width: 60,
            name: `Rotation`,
            value: (item) => item.ipChangeEnabled ? `${item.ipChangeMinutesInterval}min` : `OFF`,
            green: (item) => false,
            red: (item) => false,
            extra: ``,
        },
        {
            width: 75,
            name: `Tariff`,
            value: (item) => (item.activePlans || []).length > 0 ? plans.get((item.activePlans || [])[0].id).name : `no tariff`,
            green: (item) => false,
            red: (item) => (item.activePlans || []).length === 0,
            extra: ``,
        },
        {
            width: 50,
            name: `End`,
            value: (item) => (item.activePlans || []).length > 0 ? moment((item.activePlans || [])[0].expirationTimestamp).format(`DD.MM`) : ``,
            green: (item) => (item.activePlans || []).length && (item.activePlans || [])[0].expirationTimestamp >= +moment(),
            red: (item) => (item.activePlans || []).length === 0 || ((item.activePlans || []).length && (item.activePlans || [])[0].expirationTimestamp < +moment()),
            extra: ``,
        },
        {
            width: 45,
            name: `P1`,
            value: (item) => { return item.proxies[0].listen_service === `empty` ? `-` : item.proxies[0].listen_service },
            blue: (item) => item.proxies[0].listen_service !== `empty`,
            onClick: (item) => {
                if (item.proxies[0].listen_service !== `empty`) {
                    ipcRenderer.send(`open-puppeteer`, getProxyAccessURL(item.proxies[0]))
                }
            },
            extra: ``,
            only_table: true,
        },
        {
            width: 45,
            name: `P2`,
            value: (item) => { return item.proxies[1].listen_service === `empty` ? `-` : item.proxies[1].listen_service },
            blue: (item) => item.proxies[1].listen_service !== `empty`,
            onClick: (item) => {
                if (item.proxies[1].listen_service !== `empty`) {
                    ipcRenderer.send(`open-puppeteer`, getProxyAccessURL(item.proxies[1]))
                }
            },
            extra: ``,
            only_table: true,
        },
        {
            width: 45,
            name: `P3`,
            value: (item) => { return item.proxies[2].listen_service === `empty` ? `-` : item.proxies[2].listen_service },
            blue: (item) => {
                if (item.proxies[2] === undefined) {
                    console.log(item);
                }
                return item.proxies[2].listen_service !== `empty`
            },
            onClick: (item) => {
                if (item.proxies[2].listen_service !== `empty`) {
                    ipcRenderer.send(`open-puppeteer`, getProxyAccessURL(item.proxies[2]))
                }
            },
            extra: ``,
            only_table: true,
        },
        {
            width: 100,
            name: `IP`,
            value: (item) => item.status.online === false ? `offline` : item.status.ip,
            green: (item) => item.status.online === true,
            red: (item) => false,
            yellow: (item) => false,
            onClick: (item) => { },
            extra: ``,
            only_table: true,
        },
    ]
}

export default useConnectionParams;
/*eslint-enable*/