/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown } from '../ui-kit/styled-templates'
import ConnectionOverview from '../tools/connection-overview'

import { API_ENDPOINT } from '../../constants/config'
import CommonHelper from '../../utils/common-helper'

import useConnectionParams from '../../hooks/useConnectionParams'

import useCurrentUser from '../../hooks/useCurrentUser'
import useKeyHandler from '../../hooks/useKeyHandler'
import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch';

let TablePage = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()

    let user_id = path[1]
    let connection_id = path[3]

    let { users, loading, phones, plans } = useMappedState(useCallback((state) => {
        return ({
            users: state.users.usersMap.toArray().filter(i => i.userRole === `franchisee`).map(i => ({ ...i, phones: state.phones.phonesMap.toArray().filter(j => j.userId === i.id || j.sharedUsersEmails.indexOf(i.email) > -1).map(i => i.id) })),
            // loading: state.users.loading || state.phones.loading,
            loading: false,
            phones: state.phones.phonesMap.toArray(),
            plans: state.plans.plansMap.toArray(),
        })
    }, []))

    let dispatch = useDispatch()
    let { currentUser, logOut } = useCurrentUser()

    let [search, setSearch] = useState(``)
    useEffect(() => { if (user_id) { setSearch(user_id) } }, [user_id])

    let visibleUsers = users
        .sort((a, b) => b.phones.length - a.phones.length)
        // .filter(i => search === `` || (i.email + i.id + i.phones.join(``)).replace(` `, ``).toLowerCase().indexOf(search.replace(` `, ``).toLowerCase()) > -1)
        .filter(i => search === `` ? false : (i.email + i.id + i.phones.join(``)).replace(` `, ``).toLowerCase().indexOf(search.replace(` `, ``).toLowerCase()) > -1)
        .filter((a, b) => b < 100)

    let visiblePhones = phones
        .filter(i => visibleUsers.length === 1 && visibleUsers.filter(j => j.phones.indexOf(i.id) > -1).length > 0)
        .filter((a, b) => b < 100)

    if (visiblePhones.filter(i => i.id.toLowerCase().indexOf(search.toLowerCase()) > -1).length === 1) {
        visiblePhones = visiblePhones.filter(i => i.id.toLowerCase().indexOf(search.toLowerCase()) > -1)
    }

    let selectedUser = visibleUsers.length === 1 ? visibleUsers[0] : undefined

    let [visiblePhonesToRender, setVisiblePhonesToRender] = useState([])

    const debouncedCallback = useDebouncedCallback(async () => {
        setVisiblePhonesToRender(visiblePhones)
        if (visibleUsers.length === 1) {
            dispatch(phonesActions.loadVisibleProxies(visiblePhones))
            dispatch(phonesActions.loadVisibleStatuses(visibleUsers))
        }
    }, 500).callback
    useEffect(debouncedCallback, [search, users.length])

    useKeyHandler(`Escape`, () => { setSearch(``) })
    useCustomDispatch(`CHANGE_IP`, (e) => {
        window.open(`${API_ENDPOINT}/changeip/${e.detail.id}/${e.detail.timestamp % 10000}`)
        new Array(10).fill(0).forEach((a, b) => { setTimeout(() => { dispatch(phonesActions.loadVisibleStatuses(visibleUsers)) }, (b + 1) * 3 * 1000) })
    })

    return (
        <>
            <Left>
                <LeftHeader>
                    <LogoutImage onClick={() => { customHandler(`OPEN_LOGOUT_POP_UP`) }} />
                    <Input
                        image={props => require(`../../assets/WebDesign_v0.1/Icons/search_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}
                        clearImage={props => require(`../../assets/WebDesign_v0.1/Icons/close_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}
                        extra={props => `width: calc(300px - 34px - 30px); border-radius: 30px; background: rgba(0, 0, 0, 0.05); border: 0px; color: ${props.theme.text.secondary};`}
                        placeholder={`Search`}
                        value={search}
                        onChange={(e) => { setSearch(e.target.value) }}
                    />
                </LeftHeader>
                <UsersList visible={visibleUsers.length > 0 && search !== ``} >
                    <TextPlaceholder visible={loading} >Loading</TextPlaceholder>
                    <TextPlaceholder visible={!loading && visibleUsers.length === 0} >Nothing found for your request</TextPlaceholder>
                    <TextPlaceholder visible={!loading && search === ``} >Use search to filter users or connections</TextPlaceholder>
                    {
                        (search === `` ? [] : visibleUsers).map((item, index) => {
                            return (
                                <Frame key={index} onClick={() => { CommonHelper.linkTo(`/user/${item.id}`); console.log(item); }} extra={props => `width: calc(100%); height: 40px; margin-top: 1px; flex-direction: row; justify-content: space-between; padding: 10px; border-radius: 12px; box-sizing: border-box; cursor: pointer; &:after { content: '${item.phones.length}'; width: 20px; align-items: flex-start; display: flex; font-size: 12px; color: #a1a1a1; }; &:hover { opacity: 0.5; background: rgba(0, 0, 0, 0.1); }; padding: 5px; box-sizing: border-box;`} >
                                    <Frame extra={props => `width: calc(100% - 20px - 10px); align-items: flex-end; margin: 0 5px; text-overflow: ellipsis; overflow: hidden; display: inline-block; font-size: 14px; font-weight: bold; color: ${props.theme.text.secondary};`} >
                                        {item.email}
                                    </Frame>
                                </Frame>
                            )
                        })
                    }
                </UsersList>
            </Left>
            <Right>
                <TableHeader>
                    {
                        columns.map((item, index) => {
                            return (
                                <Prop extra={props => `width: ${item.width}px; color: ${props.theme.background.secondary};`} key={index} >{item.name}</Prop>
                            )
                        })
                    }
                </TableHeader>
                <TableBody>
                    {
                        visiblePhonesToRender.map((item, index) => <ConnectionOverview key={index} id={item.id} />)
                    }
                </TableBody>
            </Right>
        </>
    )
}

const Prop = styled(Frame)`
    /* border: 1px solid red; */
    /* border-right: 1px solid ${props => props.theme.background.secondary}; */
    font-size: 12px;
    align-items: flex-start;
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    /* cursor: default; */
`;

const MoreImage = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/more_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    position: absolute;
    right: 0px;
    &:hover {
        transform: scale(1.2);
    }
`;

const LogoutImage = styled(Frame)`
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }
    &:after {
        content: '';
        width: 15px;
        height: 15px;
        background: url(${props => require(`../../assets/WebDesign_v0.1/Icons/logout_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain;
    }

`;

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
`;

const Left = styled(Frame)`
    width: 300px;
    height: 100%;
`;

const LeftHeader = styled(Frame)`
    flex-direction: row;
`;

const UsersList = styled(Frame)`
    width: 300px;
    height: calc(100% - 50px - 10px);
    margin-top: 10px;
    display: ${props => props.visible ? `block` : `flex`};
    overflow-y: scroll;
`;

const TextPlaceholder = styled(Frame)`
    font-size: ${props => props.visible ? 12 : 0}px;
    color: ${props => props.theme.text.secondary};
`;

const Right = styled(Frame)`
    width: calc(100% - 300px - 10px);
    margin-left: 10px;
    height: 100%;
    margin-bottom: -20px;
`;

const TableHeader = styled(Frame)`
    width: calc(100% - 300px - 10px - 35px);
    height: 50px;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    position: absolute;
    top: 10px;
    ${props => props.theme.shadow.small}
    backdrop-filter: blur(8px);
    z-index: 2;
`;

const TableBody = styled(Frame)`
    width: 100%;
    height: calc(100%);
    padding-top: 60px;
    display: block;
    overflow-y: scroll;
`;

const Connection = styled(Frame)`
    width: calc(100%);
    height: 50px;
    margin-bottom: 5px;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 12px;
    border: 2px solid ${props => props.any_errors ? props.theme.red : `rgba(0, 0, 0, 0.1)`};
    box-sizing: border-box;
    padding: 10px;
    &:hover { background: rgba(0, 0, 0, 0.05); };
`;

export default TablePage;
/*eslint-enable*/