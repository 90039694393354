/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab } from '../ui-kit/styled-templates'
import UserTab from './user-tab'
import BalanceTab from './balance-tab'
import PhonesList from './phones-list'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let updatePhone = async (data) => {
    let phone = await axios.post(`${API_ENDPOINT}/phones/update`, data)
    return phone.data
}

let ConnectionTab = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { phone, plans } = useMappedState(useCallback((state) => {
        let phone = { proxies: [], status: {}, slaveServerId: ``, ...state.phones.phonesMap.get(connection_id) }
        return ({
            phone: { ...phone, server: { ...state.phones.servers.get(phone.slaveServerId) } },
            plans: state.plans.plansMap.toArray(),
        })
    }, [connection_id]))
    let any_errors = columns.map(i => i.red === undefined ? false : +i.red(phone)).reduce((a, b) => a + b) > 0
    let currentPlan = plans !== undefined && phone.activePlans !== undefined && plans.length > 0 && phone.activePlans.length > 0 ? phone.activePlans[0] : { id: `-`, startTimestamp: +moment(), expirationTimestamp: +moment().add(1, `month`) }

    useEffect(() => {
        dispatch(phonesActions.loadProxiesForUser(user_id))
        dispatch(phonesActions.loadStatusesForUser(user_id))
    }, [user_id])

    useEffect(() => {
        let interval = setInterval(() => {
            dispatch(phonesActions.loadStatusesForUser(user_id))
        }, 5 * 1000);
        return () => { clearInterval(interval) }
    }, [])

    return (
        <Tab extra={props => `border: 3px solid ${phone.status.online === false ? props.theme.red : phone.status.online === true ? props.theme.green : `transparent`} !important;`} >
            <Form
                fields={[
                    {
                        type: `table`, name: `users table`, content: [
                            [{ children: `ID` }, { children: phone.id }],
                            [{ children: `Name` }, { children: phone.name || `NO NAME`, extra: props => `color: ${phone.name === `` ? props.theme.yellow : props.theme.text.primary} !important;` }],
                            [{ children: `IP` }, { children: phone.status.online === false ? `offline` : phone.status.ip, extra: props => `color: ${phone.status.online === true ? props.theme.green : phone.status.online === false ? props.theme.red : props.theme.text.secondary} !important;` }],
                            [{ children: `Server` }, { children: phone.server.name }],
                        ],
                    },
                ]}
                extra={`width: 100%; * { font-size: 13px; };`}
            />
            <ButtonsWrapper>
                <LocalButton
                    onClick={() => {
                        customHandler(`CHANGE_IP`, phone)
                    }}
                >
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/up_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                    Change IP
                </LocalButton>
                <LocalButton
                    onClick={async () => {
                        // let d = await axios.get(`${API_ENDPOINT}/integration/phone/${phone.id}/vpn_conf`)
                        // let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(d.data.result));
                        // let downloadAnchorNode = document.createElement('a');
                        // downloadAnchorNode.setAttribute("href", dataStr);
                        // downloadAnchorNode.setAttribute("download", `${phone.id}_config.ovpn`);
                        // document.body.appendChild(downloadAnchorNode);
                        // downloadAnchorNode.click();
                        // downloadAnchorNode.remove();
                    }}
                >
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/download_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                    OpenVPN
                </LocalButton>
                <LocalButton>
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/delete_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                    <Dropdown
                        menu={[
                            {
                                label: `Confirm`,
                                extra: props => `color: ${props.theme.red} !important;`,
                                onClick: () => {
                                    dispatch(phonesActions.deletePhone(connection_id))
                                    CommonHelper.linkTo(`/user/${user_id}`)
                                },
                            }
                        ]}
                    >
                        Delete
                </Dropdown>
                </LocalButton>
            </ButtonsWrapper>
            <ButtonsWrapper>
                <LocalButton>
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/savedmessages_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                    <Dropdown
                        menu={[
                            ...plans.map((item, index) => ({
                                label: item.name,
                                onClick: async () => {
                                    let phone = await updatePhone({
                                        id: connection_id,
                                        activePlans: [
                                            {
                                                ...currentPlan,
                                                id: item.id,
                                            }
                                        ]
                                    })
                                    dispatch(phonesActions.updatePhoneSuccess(phone))
                                },
                            })),
                            {
                                label: `Recalculate`,
                                onClick: async () => {
                                    let newPlan = plans.filter(i => i.id !== currentPlan.id)[0]
                                    let k = plans.get(currentPlan.id).price / newPlan.price
                                    let newExpirationTimestamp = (currentPlan.expirationTimestamp - +moment()) * k + +moment()
                                    let phone = await updatePhone({
                                        id: connection_id,
                                        activePlans: [
                                            {
                                                ...currentPlan,
                                                id: newPlan.id,
                                                expirationTimestamp: newExpirationTimestamp,
                                            }
                                        ]
                                    })
                                    dispatch(phonesActions.updatePhoneSuccess(phone))
                                },
                            }
                        ]}
                    >
                        Tariff
                </Dropdown>
                </LocalButton>
                <LocalButton>
                    <Frame extra={props => `width: 18px; height: 18px; background: url(${require(`../../assets/WebDesign_v0.1/Icons/sending_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}) no-repeat center center / contain; margin-right: 10px;`} />
                    <Dropdown
                        custom={
                            <Calendar
                                onChange={async (date) => {
                                    let phone = await updatePhone({
                                        id: connection_id,
                                        activePlans: [
                                            {
                                                ...currentPlan,
                                                expirationTimestamp: date,
                                            }
                                        ]
                                    })
                                    dispatch(phonesActions.updatePhoneSuccess(phone))
                                }}
                                selectedDate={(phone.activePlans || []).length > 0 ? phone.activePlans[0].expirationTimestamp : undefined}
                            />
                        }
                    >
                        Expiration
                </Dropdown>
                </LocalButton>
                <LocalButton />
            </ButtonsWrapper>
        </Tab>
    )
}

const LocalButton = styled(Frame)`
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: regular;
    font-size: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        /* opacity: 0.8;
        transform: scale(0.95); */
    };
`;

const ButtonsWrapper = styled(Frame)`
    width: 100%;
    flex-direction: row;
    margin-top: 15px;
    > * {
        &:last-child {
            border-right: 0px;
            margin-right: 0px;
        }
    }
`;

export default ConnectionTab;
/*eslint-enable*/