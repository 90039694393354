/*eslint-disable*/
import * as types from '../ActionTypes'
import PlansAPI from '../../api/PlansAPI';

//PLANS
let loadPlans_ = () => {
    return {
        type: types.LOAD_PLANS
    }
}

let loadPlansFail = (error) => {
    return {
        type: types.LOAD_PLANS_FAIL,
        error: error
    }
}

let loadPlansSuccess = (plans, links) => {
    return {
        type: types.LOAD_PLANS_SUCCESS,
        plans: plans
    }
}

export function loadPlansByIds(ids) {
    return (dispatch, getState) => {
        dispatch(loadPlans_());
        return PlansAPI.getPlansByIds(ids).then(
            plans => dispatch(loadPlansSuccess(plans)),
            error => dispatch(loadPlansFail(error))
        )
    }
}

//load all plans
export function loadAllPlans() {
    return (dispatch, getState) => {
        dispatch(loadPlans_())
        return PlansAPI.getAllPlans().then(
            plans => dispatch(loadPlansSuccess(plans)),
            err => dispatch(loadPlansFail(err))
        )
    }
}

/*eslint-enable*/