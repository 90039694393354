/*eslint-disable*/
import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { Frame, Button } from '../ui-kit/styled-templates'
import Form from '../ui-kit/Form'

import useCurrentUser from '../../hooks/useCurrentUser'

let GuestApp = (props) => {

    let [info, setInfo] = useState({})
    let { login } = useCurrentUser()

    return (
        <Wrapper>
            <Frame extra={`align-items: flex-start;`} >
                <Title extra={`margin-bottom: 15px;`} />
                <Form
                    data={info}
                    extra={`width: 350px;`}
                    fields={[
                        { type: `input`, name: `Email`, extra: `width: 350px;`, },
                        { type: `password`, name: `Password`, extra: `width: 350px;`, },
                        { type: `button`, name: `iproxy.online`, onClick: async () => { window.open(`https://iproxy.online`) }, extra: `width: 40%;`, shaped: true, },
                        { type: `button`, name: `Login`, onClick: async () => { login(info) }, extendedIndicators: true, extra: `width: 58%;`, disabled: !info.email || !info.password },
                    ]}
                    onChange={(key, value) => { setInfo({ ...info, [key]: value }) }}
                />
            </Frame>
        </Wrapper>
    )
}

const Title = styled(Frame)`
    font-size: 36px;
    font-weight: bold;
    width: calc(143px * 1.5);
    height: calc(21px * 1.5);
    background: url(${props => require(`../../assets/images/iproxy_logo_text.svg`).default}) no-repeat center center / contain;
`;

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;

    @media (min-width: 320px) and (max-width: 480px) {
        
    }

    @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 44px - 70px);
    }

    @media (display-mode: standalone) {
        height: calc(100vh - 44px);
    }
`;

export default GuestApp;
/*eslint-enable*/