/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT, LOGIN_ENDPOINT } from "../constants/config";

const PlansAPI = {

    getAllPlans(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/plans/all`).then(d => d.data).then(
                plans => {
                    resolve(plans.result);
                }
            ).catch(err => reject(err))
        });
    },

}

export default PlansAPI;

/*eslint-enable*/