/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Button, Tab } from '../ui-kit/styled-templates'
import ConnectionOverview from '../tools/connection-overview'
import UserTab from '../tools/user-tab'
import BalanceTab from '../tools/balance-tab'
import PhonesList from '../tools/phones-list'
import ConnectionTab from '../tools/connection-tab'
import PhoneStatusTab from '../tools/phone-status-tab'
import ProxyTab from '../tools/proxy-tab'
import TransactionsList from '../tools/transactions-list'
import UsersList from '../tools/users-list'
import ProxyList from '../tools/proxy-list'

import { API_ENDPOINT } from '../../constants/config'
import CommonHelper from '../../utils/common-helper'

import useConnectionParams from '../../hooks/useConnectionParams'

import useCurrentUser from '../../hooks/useCurrentUser'
import useKeyHandler from '../../hooks/useKeyHandler'
import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch';
import TransactinosList from '../tools/transactions-list';

let SinglePage = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()

    let user_id = path[1]
    let connection_id = path[3]

    let dispatch = useDispatch()

    useCustomDispatch(`CHANGE_IP`, (e) => {
        window.open(`${API_ENDPOINT}/changeip/${e.detail.id}/${e.detail.timestamp % 10000}`)
        // new Array(10).fill(0).forEach((a, b) => { setTimeout(() => { dispatch(phonesActions.loadStatusesForUser(user_id)) }, (b + 1) * 3 * 1000) })
    })

    let [phonesVisible, setPhonesVisible] = useState(false)
    let phonesVisibleRef = useRef(phonesVisible)
    useEffect(() => { phonesVisibleRef.current = phonesVisible }, [phonesVisible])
    useEffect(() => { if (user_id === undefined) { setPhonesVisible(false) } }, [user_id])
    useCustomDispatch('SWITCH_PHONES_MOBILE', () => { setPhonesVisible(!phonesVisibleRef.current) })

    let state = { user_id, connection_id, phonesVisible }

    return (
        <Wrapper>

            <First state={state} >
                <UsersList />
            </First>

            <Second state={state} >
                {
                    user_id !== undefined ? <>
                        <Title>
                            <BackButton onClick={() => { CommonHelper.linkTo(`/`) }} />
                            User
                        </Title>
                        <UserTab />
                        <PhonesButton onClick={() => { customHandler(`SWITCH_PHONES_MOBILE`) }} >
                            Phones
                            <Frame>show</Frame>
                        </PhonesButton>
                        <Title>Balance</Title>
                        <BalanceTab />
                        <TransactionsList user_id={user_id} />
                    </> : null
                }
            </Second>

            <Third state={state} >
                {
                    user_id !== undefined ? <>
                        <Title>
                            <BackButton onClick={() => { customHandler(`SWITCH_PHONES_MOBILE`) }} />
                            Phones
                        </Title>
                        <PhonesList />
                    </> : null
                }
            </Third>

            <Fourth state={state} >
                {
                    connection_id !== undefined ? <>
                        <Title>
                            <BackButton onClick={() => { CommonHelper.linkTo(`/user/${user_id}`) }} />
                            Connection
                        </Title>
                        <ConnectionTab />
                        <PhoneStatusTab />
                        <Title>Proxy</Title>
                        <ProxyList />
                    </> : null
                }
            </Fourth>

        </Wrapper>
    )
}

const BackButton = styled(Frame)`
    display: none;
    width: 6vw;
    height: 6vw;
    background: url("${props => require(`../../assets/WebDesign_v0.1/Icons/next_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}") no-repeat center center / contain;
    transform: rotate(180deg);

    @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
    }
`;

const Block = styled(Frame)`
    * { color: ${props => props.theme.text.secondary} };
    box-sizing: border-box;
    display: block;
    overflow-y: scroll;
    padding: 10px 0px 0 0px;
    margin-left: 10px;
    max-height: 100vh;
    min-height: 100%;
    overflow-x: visible;

    @media (min-width: 320px) and (max-width: 480px) {
        height: auto;
        overflow: visible;
        margin-left: 0px;
        /* min-height: 100%; */
        max-height: none;
        width: 100vw;
    }

    @media (display-mode: standalone) {
        min-height: calc(100vh - 44px - 2px);
    }

`;

const Fourth = styled(Block)`
    width: 450px;
    margin-right: 10px;
    
    @media (min-width: 320px) and (max-width: 480px) {
        margin-right: 0px;
        ${props => props.state.connection_id !== undefined ? `` : `display: none;`};
    }
`;

const Third = styled(Block)`
    width: 350px;

    @media (min-width: 320px) and (max-width: 480px) {
        ${props => props.state.phonesVisible === true && props.state.user_id !== undefined && props.state.connection_id === undefined ? `` : `display: none;`};
    }
`;

const Second = styled(Block)`
    width: 350px;

    @media (min-width: 320px) and (max-width: 480px) {
        ${props => props.state.phonesVisible === false && props.state.user_id !== undefined && props.state.connection_id === undefined ? `` : `display: none;`};
    }
`;

const First = styled(Block)`
    width: calc(100% - 350px - 350px - 450px);

    @media (min-width: 320px) and (max-width: 480px) {
        ${props => props.state.user_id === undefined ? `` : `display: none;`};
    }
`;

const Title = styled(Frame)`
    width: 100%;
    font-size: 24px;
    color: ${props => props.theme.text.primary} !important;
    font-weight: bold;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 8vw;
    }
`;

const PhonesButton = styled(Title)`
    display: none;

    @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
        > * {
            &:nth-child(1) {
                margin-right: 4vw;
                flex-direction: row;
                &:after {
                    content: '';
                    width: 4vw;
                    height: 4vw;
                    background: url("${props => require(`../../assets/WebDesign_v0.1/Icons/up_svg${props.theme.name === `dark` ? `_w` : ``}.svg`).default}") no-repeat center center / contain;
                    transform: rotate(90deg);
                }
            }
        }
    }
`;

const Wrapper = styled(Frame)`
    width: calc(100vw);
    height: 100vh;
    /* padding: 10px; */
    box-sizing: border-box;
    /* background: rgba(0, 0, 0, 0.05); */
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        height: auto;
        flex-direction: column;
        > * {
            width: 95vw;
            justify-content: flex-start;
        }
    }

    @supports (-webkit-touch-callout: none) {
        
    }
`;

export default SinglePage;
/*eslint-enable*/