/*eslint-disable*/
import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useMappedState } from 'redux-react-hook'
import moment from 'moment-timezone'
import axios from 'axios'

import * as phonesActions from '../../redux/actions/PhonesActions'

import { Frame, Text, H1 } from '../ui-kit/styled-templates'
import Button from '../ui-kit/Button'
import PopUpWrapper from '../pop-ups/pop-up-wrapper'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useCurrentUser from '../../hooks/useCurrentUser'
import useComponentSize from '../../hooks/useComponentSize'
import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch'

let CreateProxyPopUp = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)

    let user_id = path[1]
    let connection_id = path[3]

    let dispatch = useDispatch()

    useCustomDispatch(`OPEN_CREATE_PROXY_POP_UP`, (e) => { })
    useCustomDispatch(`CLOSE_CREATE_PROXY_POP_UP`, (e) => { })

    let { user, phone } = useMappedState(useCallback((state) => {
        let currentUser = { email: ``, ...state.users.usersMap.get(user_id) }
        return ({
            user: currentUser,
            phone: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) },
        })
    }, [path]))

    let createProxy = async (listen_service = `http`, auth_type = `userpass`) => {
        let new_proxy = {
            "phoneId": connection_id,
            "auth_type": auth_type,
            "listen_service": listen_service,
            "expirationTimestamp": +moment().add(1000, `years`),
            "description": ""
        }
        // await axios.post(`${API_ENDPOINT}/connections/to_phone/create`, new_proxy)
        // console.log(`> > > created`);
        // let connections = (await axios.get(`${API_ENDPOINT}/user/${user_id}/connections`)).data
        // console.log(`> > > loaded new proxy`);
        // dispatch(phonesActions.loadVisibleProxiesSuccess(connections))
        dispatch(phonesActions.createProxy(new_proxy))
    }

    return (
        <PopUpWrapper name={`CREATE_PROXY`} >
            <Frame extra={`flex-direction: row; flex-wrap: wrap; width: 300px;`} >
                {
                    [`http`, `socks5`].map((a, a_i) => {
                        return [`userpass`, `noauth`].map((b, b_i) => {
                            return (
                                <Square key={`${a_i}_${b_i}`} onClick={() => { createProxy(a, b); customHandler(`CLOSE_CREATE_PROXY_POP_UP`) }} >
                                    <Frame>
                                        <Frame>{b}</Frame>
                                        <Frame>{a}</Frame>
                                    </Frame>
                                </Square>
                            )
                        })
                    })
                }
            </Frame>
        </PopUpWrapper>
    );
}

const Square = styled(Frame)`
    width: 150px;
    height: 50px;
    cursor: pointer;
    &:hover {
        transform: scale(0.95);
    }
    > * {
        align-items: flex-end;
        > * {
            &:nth-child(1) {
                font-size: 12px;
                color: ${props => props.theme.text.secondary};
                opacity: 0.8;
            }
            &:nth-child(2) {
                font-size: 16px;
                color: ${props => props.theme.text.secondary};
            }
        }
    }
`

const Avatar = styled(Frame)`
    width: 142px;
    height: 142px;
    border-radius: 142px;
    background: ${props => props.theme.background.secondary};
    position: absolute;
    top: calc(50% - 190px - 71px);
    box-shadow: 0px 20px 60px rgba(87, 138, 214, 0.2);
`;

let Cross = styled.img.attrs((props) => {
    let img
    try { img = require(`../../assets/images/cross_w.svg`).default } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: calc(50% - 300px);
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    width: 600px;
    height: 250px;
    border-radius: 12px;
    background: ${props => props.theme.background.primary};
    position: absolute;
    top: ${props => props.visible ? `calc(50% - 190px)` : `150%`};
    z-index: 3;
    // justify-content: space-between;

    @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        width: 90vw;
        padding: 9vw 3vw;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, ${props => props.visible * 0.75});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;


const Wrapper = styled(Frame)`
    position: relative;
    padding-top: 94px;
    overflow: hidden;
`;

export default CreateProxyPopUp;

/*eslint-enable*/