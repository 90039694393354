/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab } from '../ui-kit/styled-templates'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let UserTab = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { user } = useMappedState(useCallback((state) => ({ user: { email: ``, ...state.users.usersMap.get(user_id) }, }), [user_id]))

    let [localUser, setLocalUser] = useState(user)
    useEffect(() => { setLocalUser(user) }, [user])

    // console.log(`> > >`);
    
    return (
        <Tab>
            <Form
                data={localUser}
                fields={[
                    {
                        type: `table`, name: `users table`, content: [
                            [{ children: `ID` }, { children: user.id }],
                            [{ children: `Email` }, { children: user.email, extra: `color: black;` }],
                        ]
                    },
                    { type: `input`, name: `telegram`, extra: `width: calc(100%);`, },
                    {
                        type: `button`, name: `Update`, extendedIndicators: true, onClick: async () => {
                            try {
                                await axios.post(`${API_ENDPOINT}/users/update`, { id: user_id, telegram: localUser.telegram })
                            } catch (error) {
                                console.log(error);
                            }
                        }, extendedIndicators: true, extra: `width: 145px;`
                    },
                    {
                        type: `button`, name: `Open`, onClick: async () => {
                            window.open(`https://t.me/${localUser.telegram}`)
                        }, extra: `width: 145px;`
                    },
                    { type: `custom`, name: `password label`, component: <Frame extra={`width: 300px; align-items: flex-start;`} >New password</Frame> },
                    { type: `input`, name: `password`, extra: `width: calc(100% - 110px);`, },
                    {
                        type: `button`, name: `Reset`, extendedIndicators: true, onClick: async () => {
                            if (localUser.password === `` || localUser.password === undefined) {
                                window.alert(`Password is empty`)
                                throw (`Password is empty`)
                            }
                            await axios.post(`${API_ENDPOINT}/users/update`, { id: user_id, password: localUser.password })
                        }, confirm: true, extra: `width: 100px;`
                    },
                ]}
                onChange={(key, value) => { setLocalUser({ ...localUser, [key]: value }) }}
                extra={`width: 100%; * { font-size: 13px; }`}
            />
        </Tab>
    )
}

export default UserTab;
/*eslint-enable*/