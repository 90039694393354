/*eslint-disable*/
import {Map, Set} from 'immutable'
import * as types from '../ActionTypes'

const initialState = {
    plansMap: Map(),
    currentUserId: undefined,
    loading: false,
    error: undefined,
    initialized: false
}

const PlansReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.LOAD_PLANS:
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case types.LOAD_PLANS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case types.LOAD_PLANS_SUCCESS:
            return {
                ...state,
                plansMap: state.plansMap.merge(action.plans.reduce((res, u) => {
                    return res.set(u.id, u)
                }, Map())),
                loading: false
            }

        default:
            return state;

    }

}

export default PlansReducer;

/*eslint-enable*/