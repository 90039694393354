/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT, LOGIN_ENDPOINT } from "../constants/config";

const PhonesAPI = {

    getAllPhones(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/phones/all`).then(d => d.data).then(
                phones => {
                    resolve(phones);
                }
            ).catch(err => reject(err))
        });
    },

    getVisibleProxies(localUsers) {
        return new Promise(async (resolve, reject) => {
            try {
                let connections = Object.values((await Promise.all(localUsers.map(async (item, index) => Object.fromEntries((await axios.get(`${API_ENDPOINT}/user/${item}/connections`)).data.map(i => [i.id, i]))))).reduce((a, b) => ({ ...a, ...b }), {}))
                resolve(connections)
            } catch (error) {
                reject(error)
            }
        });
    },

    getProxiesForUser(user_id) {
        return new Promise(async (resolve, reject) => {
            try {
                let connections = (await axios.get(`${API_ENDPOINT}/user/${user_id}/connections`)).data
                resolve(connections)
            } catch (error) {
                reject(error)
            }
        });
    },

    getVisibleStatuses(visibleUsers) {
        return new Promise(async (resolve, reject) => {
            try {
                let statuses = (await Promise.all(visibleUsers.map(i => i.id).map(async item => (await axios.get(`https://iproxy.online/api-rt/user/${item}/phones/status`)).data))).reduce((a, b) => ({ ...a, ...b }), {})
                resolve(statuses)
            } catch (error) {
                reject(error)
            }
        });
    },

    getStatusesForUser(user_id) {
        return new Promise(async (resolve, reject) => {
            try {
                let statuses = (await axios.get(`https://iproxy.online/api-rt/user/${user_id}/phones/status`)).data
                resolve(statuses)
            } catch (error) {
                reject(error)
            }
        });
    },

    updatePhone(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let phone = await axios.post(`${API_ENDPOINT}/phones/update`, data)
                resolve(phone.data);
            } catch (error) {
                reject(error)
            }
        });
    },

    deletePhone(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let d = await axios.post(`${API_ENDPOINT}/phones/smart_delete`, { id })
                resolve(d.data.id);
            } catch (error) {
                reject(error)
            }
        });
    },

    createProxy(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let d = await axios.post(`${API_ENDPOINT}/connections/to_phone/create`, data)
                resolve(d.data);
            } catch (error) {
                reject(error)
            }
        });
    },

    deleteProxy(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let d = await axios.post(`${API_ENDPOINT}/connections/smart_delete`, { id })
                resolve(d.data.id);
            } catch (error) {
                reject(error)
            }
        });
    },

    loadServers() {
        return new Promise(async (resolve, reject) => {
            try {
                let d = await axios.get(`${API_ENDPOINT}/servers/all`)
                resolve(d.data);
            } catch (error) {
                reject(error)
            }
        });
    },

}

export default PhonesAPI;

/*eslint-enable*/