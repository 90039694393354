/*eslint-disable*/
import { Map, Set } from 'immutable'
import * as types from '../ActionTypes'

const initialState = {
    phonesMap: Map(),
    currentUserId: undefined,
    loading: false,
    error: undefined,
    initialized: false,
    servers: [],
}

let emptyStatus = {
    listen_service: `empty`,
    port: undefined,
    address: undefined,
    auth_type: undefined,
    login: undefined,
    password: undefined,
    phoneId: undefined,
}

const PhonesReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.LOAD_PHONES:
        case types.LOAD_PROXIES:
        // case types.LOAD_STATUSES:
        case types.UPDATE_PHONE:
        case types.DELETE_PHONE:
        case types.CREATE_PROXY:
        case types.DELETE_PROXY:
        case types.LOAD_SERVERS:
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case types.LOAD_PHONES_FAIL:
        case types.LOAD_PROXIES_FAIL:
        // case types.LOAD_STATUSES_FAIL:
        case types.UPDATE_PHONE_FAIL:
        case types.DELETE_PHONE_FAIL:
        case types.CREATE_PROXY_FAIL:
        case types.DELETE_PROXY_FAIL:
        case types.LOAD_SERVERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case types.LOAD_PHONES_SUCCESS:
            return {
                ...state,
                phonesMap: state.phonesMap.merge(action.phones.reduce((res, u) => {
                    return res.set(u.id, { proxies: new Array(3).fill(emptyStatus), status: { online: undefined, ip: `loading`, }, ...u })
                }, Map())),
                loading: false
            }

        case types.LOAD_PROXIES_SUCCESS:
            return {
                ...state,
                phonesMap: state.phonesMap.map(i => ({ ...i, proxies: [...action.connections.filter(j => j.phoneId === i.id), ...new Array(3).fill(emptyStatus)].slice(0, 3) })).reduce((res, u) => {
                    return res.set(u.id, u)
                }, Map()),
                loading: false,
            }

        case types.LOAD_STATUSES_SUCCESS:
            return {
                ...state,
                phonesMap: state.phonesMap.map(i => ({ ...i, status: { ...i.status, ...(action.statuses[i.id] || {}) } })).reduce((res, u) => {
                    return res.set(u.id, u)
                }, Map()),
                loading: false,
            }

        case types.UPDATE_PHONE_SUCCESS:
            return {
                ...state,
                phonesMap: state.phonesMap.map(i => ({ ...i, ...(i.id === action.phone.id ? action.phone : {}) })).reduce((res, u) => {
                    return res.set(u.id, u)
                }, Map()),
                loading: false
            }

        case types.DELETE_PHONE_SUCCESS:
            return {
                ...state,
                phonesMap: state.phonesMap.filter(i => i.id !== action.id).reduce((res, u) => {
                    return res.set(u.id, u)
                }, Map()),
                loading: false
            }

        case types.DELETE_PROXY:
        case types.DELETE_PROXY_SUCCESS:
            return {
                ...state,
                phonesMap: state.phonesMap.toArray().map(user => ({ ...user, proxies: [...user.proxies, ...new Array(3).fill(emptyStatus)].filter(i => i.id !== action.id).slice(0, 3) })).reduce((res, u) => {
                    return res.set(u.id, u)
                }, Map()),
                loading: false,
            }

        case types.CREATE_PROXY_SUCCESS:
            // ISSUE
            console.log(state.phonesMap.toArray().map(user => ({ ...user, proxies: [...user.proxies, action.proxy, ...new Array(3).fill(emptyStatus)].slice(0, 3) })).get(`509bM4bOAg`));
            return {
                ...state,
                // phonesMap: state.phonesMap.map(i => ({ ...i, proxies: [...[action.proxy].filter(j => j.phoneId === i.id), ...new Array(3).fill(emptyStatus)].slice(0, 3) })).reduce((res, u) => {
                //     return res.set(u.id, u)
                // }, Map()),
                loading: false,
            }

        case types.LOAD_SERVERS_SUCCESS:
            return {
                ...state,
                servers: action.servers,
                loading: false,
            }

        default:
            return state;

    }

}

export default PhonesReducer;

/*eslint-enable*/