/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Calendar, Button, Tab, convertHex } from '../ui-kit/styled-templates'
import UserTab from './user-tab'
import BalanceTab from './balance-tab'
import PhonesList from './phones-list'
import ConnectionTab from './connection-tab'
import Form from '../ui-kit/Form'

import CommonHelper from '../../utils/common-helper'
import { API_ENDPOINT } from '../../constants/config'

import useKeyHandler from '../../hooks/useKeyHandler'
import useConnectionParams, { getProxyAccessURL } from '../../hooks/useConnectionParams'
import { customHandler } from '../../hooks/useCustomDispatch'

if (window.require === undefined) {
    window.require = () => ({ ipcRenderer: { send: () => { } } })
}
let ipcRenderer = window.require(`electron`).ipcRenderer

let PhoneStatusTab = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let columns = useConnectionParams()
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { phone } = useMappedState(useCallback((state) => {
        return ({
            phone: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) },
        })
    }, [path]))

    let any_errors = columns.map(i => i.red === undefined ? false : +i.red(phone)).reduce((a, b) => a + b) > 0
    let any_warnings = columns.map(i => i.yellow === undefined ? false : +i.yellow(phone)).reduce((a, b) => a + b) > 0
    let everything_is_ok = !any_errors && !any_warnings

    return (
        <Tab extra={props => `border: 3px solid ${phone.status.online === undefined ? `transparent` : any_errors ? props.theme.red : any_warnings ? props.theme.yellow : everything_is_ok ? props.theme.green : `transparent`} !important;`} >
            {
                columns.filter(i => !i.only_table).map((column, index) => {
                    let item = phone
                    let red = column.red !== undefined ? column.red(item) : false
                    let green = column.green !== undefined ? column.green(item) : false
                    let yellow = column.yellow !== undefined ? column.yellow(item) : false
                    let blue = column.blue !== undefined ? column.blue(item) : false
                    return (
                        <Plate key={index} extra={props => `color: ${red ? props.theme.red : green ? props.theme.green : yellow ? props.theme.yellow : blue ? props.theme.blue : props.theme.text.secondary} !important; font-weight: ${red || green || yellow || blue ? `700` : `600`}; ${column.extra};`} >
                            <Frame>{column.name}</Frame>
                            {column.value(item)}
                        </Plate>
                    )
                })
            }
        </Tab>
    )
}

const Plate = styled(Frame)`
    width: 33%;
    height: 70px;
    font-size: 14px;
    position: relative;
    &:hover { opacity: 0.8; };
    > * {
        &:first-child {
            position: absolute;
            top: 0;
            color: ${props => convertHex(props.theme.text.secondary, 0.5)};
            font-size: 10px;
        }
    }

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 3vw;

        > * {
        &:first-child {
            position: absolute;
            top: 0;
            color: ${props => convertHex(props.theme.text.secondary, 0.5)};
            font-size: 3vw;
        }
    }
    }
`;

export default PhoneStatusTab;
/*eslint-enable*/