/*eslint-disable*/
import React, { useState } from 'react'
import styled from 'styled-components'
import { Frame, Text, Image, PopUp, Textarea, Input, Checkbox, P, Button } from './styled-templates'
import moment from 'moment'
import InputMask from 'react-input-mask';
import Select from 'react-select'

let { min, max } = Math

let validateDate = (date) => {
    date = date.split(`.`)
    if (!isNaN(+date[0])) { date[0] = Math.min(31, +date[0]) }
    if (!isNaN(+date[1])) { date[1] = Math.min(12, +date[1]) }
    if (!isNaN(+date[2])) { date[2] = Math.max(+moment().format(`YYYY`), Math.min(2120, +date[2])) }
    return date.join(`.`)
}

let Form = (props) => {

    let { fields = [], onChange = () => { }, data = {}, editable = true, extra = `` } = props

    return (
        <Wrapper extra={extra} >
            {
                fields.map((item, index) => {
                    let key = item.name.toLocaleLowerCase().split(` `).join(`_`)
                    if (editable === false) {
                        return <Frame key={index} >
                            <Label>{item.name}</Label>
                            <Text extra={`margin: 0.5vw;`} bold >{data ? data[key] || `` : ``}</Text>
                        </Frame>
                    }
                    switch (item.type) {
                        case `input`:
                        case `number`:
                            return (
                                <Input key={index} {...item} value={data ? data[key] || `` : ``} onChange={e => { onChange(key, e.target.value) }} placeholder={item.placeholder || item.name} />
                            )
                        case `password`:
                            return (
                                <Input key={index} value={data ? data[key] || `` : ``} type={`password`} onChange={e => { onChange(key, e.target.value) }} short={item.short} extra={item.extra} placeholder={item.name || `Password`} />
                            )
                        case `date`:
                            return (
                                <InputMask key={index} mask="99.99.9999" value={data ? data[key] || `` : ``} onChange={e => { onChange(key, validateDate(e.target.value)) }} short={item.short} extra={item.extra} placeholder={item.name} pattern={`[0-9]*`} >
                                    {(props) => <Input number {...props} />}
                                </InputMask>
                            )
                        case `phone`:
                            return (
                                <InputMask key={index} mask="+370 99 999 999" value={data ? data[key] || `` : ``} onChange={e => { onChange(key, e.target.value) }} short={item.short} extra={item.extra} placeholder={item.name} pattern={`[0-9]*`} >
                                    {(props) => <Input {...props} />}
                                </InputMask>
                            )
                        case `textarea`:
                            return (
                                <Textarea key={index} value={data ? data[key] || `` : ``} onChange={e => { onChange(key, e.target.value) }} short={item.short} extra={item.extra} placeholder={item.name} />
                            )
                        case `select`:
                            let labelTransformer = (i) => {
                                if (item.labelTransformer !== undefined) {
                                    return item.labelTransformer(i)
                                }
                                return i
                            }
                            return (
                                <MySelect
                                    key={index}
                                    isMulti={item.isMulti === true}
                                    closeMenuOnSelect={true}
                                    defaultValue={[]}
                                    value={data ? { value: data[key], label: labelTransformer(data[key]) } || { value: `option`, label: `option` } : { value: `option`, label: `option` }}
                                    options={item.options.map(i => ({ label: labelTransformer(i), value: i })) || []}
                                    onChange={e => { onChange(key, e.value) }}
                                    extra={item.extra}
                                />
                            )
                        case `checkbox`:
                            return <Frame key={index} {...item} extra={item.extra + `flex-direction: row;`} >
                                <Checkbox
                                    checked={data[key] === true}
                                    onChange={e => { onChange(key, data[key] === false) }}
                                />
                                <Text extra={`margin-left: 15px; font-size: 16px; margin-bottom: 0px;`} >{item.name}</Text>
                            </Frame>
                        case `button`:
                            return <Button key={index} {...item} >{item.name}</Button>
                        case `table`:
                            return (item.content || []).map((row, row_index) => {
                                return (
                                    <Frame key={`${index}_${row_index}`} extra={`flex-direction: row; justify-content: space-between; width: 100%;`} >
                                        {
                                            (row || []).map((i, index) => {
                                                return <Frame key={index} {...i} />
                                            })
                                        }
                                    </Frame>
                                )
                            })
                        case `custom`:
                            return <Frame key={index} extra={item.extra} >
                                {item.component}
                            </Frame>
                        default: return null
                    }
                })
            }
        </Wrapper >
    )
}

const MySelect = styled(Select).attrs((props) => {
    return ({
        styles: {
            control: styles => ({
                ...styles,
                width: `350px`,
                height: `40px`,
                borderRadius: `6px`,
                border: `1px solid ${props.theme.background.support}`,
                background: `rgba(255, 255, 255, 0.05)`,
                fontSize: `14px`,
                ...props.extra
            }),

            menu: style => ({
                ...style,
                borderRadius: `6px`,
                margin: 0,
                padding: 0,
                maxHeight: `400px`,
                background: props.theme.background.overlay,
                overflow: `hidden`,
            }),

            menuList: style => ({
                ...style,
                margin: 0,
                padding: 0,
            }),

            placeholder: style => ({ ...style, marginLeft: `12px`, fontSize: `14px`, }),
            input: style => ({ marginLeft: `12px`, display: `none`, }),

            indicatorSeparator: style => ({
                opacity: 0,
            }),
            option: styles => ({
                ...styles,
                background: props.theme.background.overlay,
                color: props.theme.text.primary,
                zIndex: 2000,
                fontSize: `14px`,
                ':hover': { backgroundColor: props.theme.background.support, },
            }),
            singleValue: style => ({
                color: props.theme.text.primary,
            })
        }
    })
})`
    
`;

let Label = styled(Text)`
    margin: 0.5vw;
    color: ${props => props.theme.text.secondary};
`

let Wrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 540px;

    > * {
        align-items: flex-start;
        margin-bottom: 20px;
    }

    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin: 0 !important;

        > * {
            margin-bottom: 6.25vw;
        }
    }
`;

export default Form;
/*eslint-enable*/