/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment-timezone'
import { Switch, Route, useLocation } from 'react-router-dom'

import * as phonesActions from '../../redux/actions/PhonesActions'
import * as plansActions from '../../redux/actions/PlansActions'
import * as usersActions from '../../redux/actions/UsersActions'

import { Frame, Input, Dropdown, Button, Tab } from '../ui-kit/styled-templates'
import ConnectionOverview from '../tools/connection-overview'
import UserTab from '../tools/user-tab'
import BalanceTab from '../tools/balance-tab'
import PhonesList from '../tools/phones-list'
import ConnectionTab from '../tools/connection-tab'
import PhoneStatusTab from '../tools/phone-status-tab'
import ProxyTab from '../tools/proxy-tab'
import TransactionsList from '../tools/transactions-list'
import UsersList from '../tools/users-list'

import { API_ENDPOINT } from '../../constants/config'
import CommonHelper from '../../utils/common-helper'

import useConnectionParams from '../../hooks/useConnectionParams'

import useCurrentUser from '../../hooks/useCurrentUser'
import useKeyHandler from '../../hooks/useKeyHandler'
import useCustomDispatch, { customHandler } from '../../hooks/useCustomDispatch';
import TransactinosList from '../tools/transactions-list';

let ProxyList = (props) => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let dispatch = useDispatch()

    let user_id = path[1]
    let connection_id = path[3]

    let { proxies } = useMappedState(useCallback((state) => {
        return ({
            proxies: { proxies: [], status: {}, ...state.phones.phonesMap.get(connection_id) }.proxies,
        })
    }, []))

    return <>
        {
            proxies.filter(i => i.phoneId === connection_id).map((item, index) => <ProxyTab key={index} id={item.id} />)
        }
        {
            proxies.filter(i => i.listen_service !== `empty`).length < 3 ? <Button extra={`font-size: 13px; cursor: pointer; margin-bottom: 15px;`} onClick={() => {
                customHandler('OPEN_CREATE_PROXY_POP_UP')
            }} >
                + add proxy access
            </Button> : null
        }
    </>
}

export default ProxyList;
/*eslint-enable*/