/*eslint-disable*/
import { combineReducers } from 'redux';

import PhonesReducer from './PhonesReducer.js';
import PlansReducer from './PlansReducer.js';
import UsersReducer from './UsersReducer.js';

export const reducer = combineReducers({
    phones: PhonesReducer,
    plans: PlansReducer,
    users: UsersReducer,
});

/*eslint-enable*/
